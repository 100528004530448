import React from "react";

// Modules
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// App
import { serverUrl, apiEndpoints, counterEnabled, favoritesEnabled } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import Pagination from "../../partials/pagination";
import PageHeader from "../../partials/pageHeader";
import { alertMessages } from "../../partials/alertMessages";
import Error from "../../partials/error";
import NoData from "../../partials/noData";
import { decodeHtmlEntities, favoritesContentType } from "../../utils/helpers";
import ListHeader from "../../partials/listHeader";
import ContactItem from "./contactItem";
import ClientTheme from "../../core/clientTheme";
import { patchNode } from "../../core/postNode";

// UI components
import { Row } from "react-bootstrap";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import CompanyappLoader from "../../partials/companyappLoader";

const contentType = favoritesContentType("people");

export default function Contacts(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [index, setIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [token, setToken] = useState("");
  const [data, setData] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(0);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [activeDetail, setActiveDetail] = useState("");

  const user = useSelector((state) => state).authReducer.user;
  const counter = useSelector((state) => state).counterReducer;

  // hooks
  const dispatch = useDispatch();

  const clientTheme = { ...ClientTheme() };

  const listLabels = [
    { title: "", width: { xs: 2, md: 1 }, className: "" },
    { title: "Name", width: { xs: 3, md: "auto" }, grow: 1 },
    { title: "Category", width: { xs: "auto" } },
    {
      title: "Call",
      width: { xs: 1 },
      align: "center",
      colClass: "d-flex justify-content-center d-none d-md-block",
    },
    {
      title: "Chat",
      width: { xs: 1 },
      align: "center",
      colClass: "d-flex justify-content-center d-none d-md-block",
    },
    {
      title: "Email",
      width: { xs: 1 },
      align: "center",
      colClass: "d-flex justify-content-center d-none d-md-block",
    },
    {
      title: "Fav.",
      width: { xs: 1 },
      align: "center",
      colClass: `${
        favoritesEnabled ? "d-flex" : "d-none"
      } justify-content-center`,
    },
    {
      title: "View",
      width: { xs: 1 },
      align: "center",
      colClass: "d-flex justify-content-center",
    },
  ];

  useEffect(() => {
    if (counterEnabled) {
      updateRemoteCounter();
    }
  }, []);

  useEffect(() => {
    if (categories.length <= 1) {
      getCategories();
    }
    getContent(1);
  }, [selectedCategoryItem]);

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const _setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsRefreshing(false);

    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (_currentPage) => {
    let path = `${apiEndpoints.contacts}?items_per_page=${pageSize}&page=${
      _currentPage - 1
    }`;

    getOnlineNode(path, user.access_token)
      .then((response) => {
        setData(response.data.rows);
        setIsLoading(false);
      })
      .catch((_error) => {
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          _setError(true, _error.response.status, _error.response.statusText);
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getCategories
   * @description Retrives the category data from an API / Fallback to local realm object if there is no connection
   */
  const getCategories = () => {
    getOnlineNode(
      // 'all_categories/news_categories',
      "api/categories?vid=link_categories",
      user.access_token
    )
      .then((response) => {
        renderCategories(response.data);
      })
      .catch((_error) => {
        if (_error.response) {
          _setError(true, _error.response.status, _error.response.statusText);
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  const onPaginate = (_index) => {
    setIsLoading(true);

    setCurrentPage(_index);
    getContent(_index);
  };

  /**
   * @function renderCategories
   * @description Updates the state with the retrived categories
   * @param {object} _categories
   */
  const renderCategories = (_data) => {
    let categories = [
      {
        value: 0,
        label: "All Users",
      },
    ];

    _data.forEach((item) => {
      categories.push({
        value: item.tid,
        label: item.title,
      });
    });

    setCategories(categories);
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  const handleCategorySelection = (value) => {
    setIsLoading(true);

    setIsError(false);
    setCurrentPage(1);
    setSelectedCategory(parseInt(value, 10));
    setSelectedCategoryItem(value);
    setCategoryOpen(false);
  };

  // Pagination component
  const renderPagination = () => {
    if (totalItems <= pageSize) {
      return null;
    } else {
      return (
        <Pagination
          owner="client"
          containerClasses={`creator-pagination d-flex justify-content-center align-items-center mt-4`}
          previousDisabled={currentPage === 1 ? true : false}
          nextDisabled={data.length < pageSize ? true : false}
          currentPage={currentPage}
          onPrevious={() => {
            onPaginate(currentPage - 1);
          }}
          onNext={() => {
            onPaginate(currentPage + 1);
          }}
        />
      );
    }
  };

  // Data component
  const dataComponent = () => {
    if (typeof data !== "undefined" && data.length > 0) {
      return (
        <Row className={`${!gridView ? "mx-0" : ""}`}>
          {data.map((item, index) => {
            return (
              <ContactItem
                item={item}
                gridView={gridView}
                contentType={contentType}
              />
            );
          })}
        </Row>
      );
    } else {
      return <NoData activeIndex={activeIndex} />;
    }
  };

  const updateRemoteCounter = () => {
    const updateData = {
      contentType: 'user',
      acknowledgedCount: counter?.contentCounts?.user || 0,
    };

    patchNode(`${serverUrl}/api/v2/counter`, updateData, user.csrf_token)
      .then((response) => {
        if (response.data) {
          dispatch({ type: "UPDATE_COUNTER", payload: response.data });
        }
      })
      .catch((error) => {
        // console.log('error: ', error);
      });
  };

  /**
   * @function render
   * @description Default render method
   */

  return (
    <>
      {isError ? (
        <Error status={errorStatus} message={errorMessage} />
      ) : (
        <main className="contacts  screen">
          <PageHeader
            pageName="User Directory"
            filters={true}
            owner="client"
            pageNameColor="clientHeadingScreens"
            svgLogo={clientTheme.pageLogos.svg}
            pageLogo={clientTheme.pageLogos.news}
            enableFavorites={favoritesEnabled}
            categorySelector={
              <FormControl variant="standard" fullWidth size="small">
                <InputLabel sx={{ fontSize: 14 }}>Users</InputLabel>
                <Select
                  labelId="contacts-category-select-label"
                  id="contacts-category-select"
                  value={selectedCategoryItem}
                  label="Users"
                  open={categoryOpen}
                  onClose={() => setCategoryOpen(false)}
                  onOpen={() => setCategoryOpen(true)}
                  input={
                    <Input
                      sx={{
                        fontSize: 14,
                        "&.MuiInput-underline:before": {
                          borderBottomColor: "#d2d0cf",
                        },
                      }}
                      label="Users"
                    />
                  }
                >
                  {categories.map((category, index) => {
                    return (
                      <MenuItem
                        key={`contacts-category-${category.value}`}
                        value={category.value}
                        onClick={() => {
                          handleCategorySelection(category.value);
                        }}
                      >
                        {decodeHtmlEntities(category.label)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            }
            getContent={() => {
              setActiveIndex(0);
              setIsLoading(true);
              getContent(1);
            }}
            activeTabIndex={activeIndex}
            viewToggle
            gridView={true}
            handleViewToggle={(value) => {
              setGridView(value);
            }}
          />
          {isLoading ? (
            <CompanyappLoader />
          ) : (
            <>
              {!gridView && (
                <ListHeader
                  items={listLabels}
                  labelConfig={{
                    owner: "client",
                    variant: "subtitle1",
                    color: "clientPrimary.main",
                    weight: "medium",
                    lh: "wide",
                  }}
                />
              )}
              {dataComponent()}
              {renderPagination()}
            </>
          )}
        </main>
      )}
    </>
  );
}
