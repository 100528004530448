import React from "react";
import { useNavigate } from "react-router-dom";
import GridItem from "../../partials/gridItem";

export default function Forum(props) {
  const navigate = useNavigate();

  let item = {
    title: props.item?.title,
    nid: props.item?.tid,
    body: props.item?.description__value,
  };

  return (
    <GridItem
      xs={12}
      itemClass="forum"
      bundle={props.item?.bundle}
      linkTo
      route="forums"
      nid={item?.nid}
      title={item?.title}
      field_body={item.body}
      disableChip
      cardAction={true}
      cardActionCallBack={() => navigate(`/forums/${item?.nid}`)}
    />
  );
}
