// Dependencies
import React from 'react';

// Modules
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Plyr from "plyr";
import "plyr/dist/plyr.css";

// App
import { serverUrl, apiEndpoints, client } from "../../config";
import { getOnlineNode } from '../../core/getNode';
import PostBody from '../../partials/postBody';
import { alertMessages } from '../../partials/alertMessages';
import Error from '../../partials/error';
import SkeletonSingleScreen from '../../partials/skeleton-screens/skeletonSingleScreen';
import CommentSection from '../../partials/comments/commentSection';
import { logCustomEvent } from "../../core/analytics";

// UI components
import { Typography } from '@mui/material';
import SinglesHeader from '../../partials/singlesHeader';

// Dummy data

export default function VideosSingle(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState(null);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams();

  // MutationObserver to track new video elements
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const videos = document.querySelectorAll(".plyr:not([data-initialized])");

      videos.forEach((video) => {
        new Plyr(video, {
          controls: [
            "play-large",
            "play",
            "progress",
            "current-time",
            "mute",
            "volume",
            "fullscreen",
          ],
        });
        video.setAttribute("data-initialized", "true");
      });
    });

    // Observe changes in the body for dynamically added elements
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect(); // Cleanup observer on unmount
  }, []);

  useEffect(() => {
    loadNode();
  }, []);

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */

  const setData = (_data) => {
    setPost(_data);
    setIsLoading(false);
    setIsError(false);
    // getComments();
    // getReactions();
  }

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  const loadNode = () => {
    const nid = params.id;

    if (nid) {
      getContent(nid);
      // getComments(nid)
    } else {
      setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (nid) => {
    let path = `${apiEndpoints.videos}/all/${nid}?_format=json&status=1&promote=1`

    getOnlineNode(path, user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          setData(response.data.rows[0]);
          logCustomEvent('read', response.data.rows[0], 'Video');
        } else {
          setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        // if (_error.response) {
        //   setError(
        //     true,
        //     _error.response.status,
        //     _error.response.statusText,
        //   );
        // }
        // else if (_error.request) {
        //   setError(true, 0, alertMessages.requestError.message);
        //   console.log('this got triggered 1');
        // } else {
        //   setError(true, 0, alertMessages.unkownError.message);
        //   console.log('this got triggered 2');
        // }
      });
  };

  const renderPostVideo = (post) => {
    const autoplay = client === 'equans' ? true : false;

    return (
      <div className="post-video-container">
        <video controls autoPlay={autoplay} className="post-video plyr">
          {client === 'companyapp' ? (
            <source src={`${serverUrl}/api/v2/assets?mid=${post.mid}`} alt={`Video for ${post.title}`}/>
          ) : (
            <source src={`${serverUrl}${post.field_media_video_file ? post.field_media_video_file : post.field_video}`} alt={`Video for ${post.title}`}/>
          )}
        </video>
      </div>
    );
  };

  const renderPostDescription = (post) => {
    return (
      <Typography
        className='post-video-description'
        owner='client'
        variant='body1'
        color='clientText.main'
        weight='light'
      >
      </Typography>
    )
  }

  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return (
        <Error
          status={errorStatus}
          message={errorMessage}
        />
      );
    } else {

      let category = 'Uncategorised';

      if (post.category_labels) {
        category = post.category_labels;
      }

      return (
        <main className="videos  single">
          <article className="article">
            <SinglesHeader 
              route={"/videos"}
              backBtnText={"Video Library"}
              category={category}
              timestamp={post.created}
              title={post.title}
            />
            {renderPostVideo(post)}
            <PostBody body={post.body} />
            <div className="article__body">
            </div>
            <CommentSection nid={post.nid} 
              field_allow_reactions={post.field_allow_reactions}
              field_allow_comments={post.field_allow_comments}/>
          </article>
        </main>
      );
    }
  }
}

