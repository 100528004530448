import { serverUrl } from "../../config";

export const getImageDimensions = async (images) => {
  const imagePromises = images.map(({ field_image, field_caption }) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = `${serverUrl}${field_image}`;
      img.onload = () => {
        resolve({
          src: `${serverUrl}${field_image}`,
          description: field_caption,
          width: img.width,
          height: img.height,
        });
      };
      img.onerror = () => {
        resolve({
          src: field_image,
          description: field_caption,
          width: 0,
          height: 0, // Handle errors by setting default values
        });
      };
    });
  });

  return Promise.all(imagePromises);
};

