import React from "react";

// Modules
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// App
import { apiEndpoints, serverUrl, counterEnabled } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import Pagination from "../../partials/pagination";
import PageHeader from "../../partials/pageHeader";
import { alertMessages } from "../../partials/alertMessages";
import Error from "../../partials/error";
import NoData from "../../partials/noData";
import { decodeHtmlEntities, favoritesContentType } from "../../utils/helpers";
import CompanyDirectoryItem from "./companyDirectoryItem";
import { patchNode } from "../../core/postNode";

// UI components
import Row from "react-bootstrap/Row";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import CompanyappLoader from "../../partials/companyappLoader";

const contentType = favoritesContentType("company_directory");

export default function CompanyDirectory(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [index, setIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [token, setToken] = useState("");
  const [data, setData] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(0);
  const [categoryOpen, setCategoryOpen] = useState(false);

  const user = useSelector((state) => state).authReducer.user;
  const counter = useSelector((state) => state).counterReducer;

  // hooks
  const dispatch = useDispatch();

  useEffect(() => {
    if (counterEnabled) {
      updateRemoteCounter();
    }
  }, []);

  useEffect(() => {
    if (categories.length <= 1) {
      getCategories();
    }
    getContent(1);
  }, []);

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const _setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsRefreshing(false);

    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (_currentPage) => {
    let path = `${
      apiEndpoints.company_directory
    }?status=1&promoted=1&items_per_page=${pageSize}'&page=${_currentPage - 1}`;

    const tid = selectedCategory;
    if (tid !== 0) {
      path =
        apiEndpoints.company_directory +
        tid +
        "/all" +
        "?status=1&promote=1&" +
        "items_per_page=" +
        pageSize +
        "&page=" +
        (_currentPage - 1);
    }

    getOnlineNode(path, user.access_token)
      .then((response) => {
        // dataHandler(response.data);
        setData(response.data.rows);
        setTotalItems(response.data.pager.total_items);
        setIsLoading(false);
      })
      .catch((_error) => {
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          _setError(true, _error.response.status, _error.response.statusText);
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getCategories
   * @description Retrives the category data from an API / Fallback to local realm object if there is no connection
   */
  const getCategories = () => {
    getOnlineNode(
      // 'all_categories/news_categories',
      `${apiEndpoints.categories}galleries_categories`,
      user.access_token
    )
      .then((response) => {
        renderCategories(response.data);
      })
      .catch((_error) => {
        if (_error.response) {
          _setError(true, _error.response.status, _error.response.statusText);
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  const onPaginate = (_index) => {
    setIsLoading(true);

    setCurrentPage(_index);
    getContent(_index);
  };

  /**
   * @function renderCategories
   * @description Updates the state with the retrived categories
   * @param {object} _categories
   */
  const renderCategories = (_data) => {
    let categories = [
      {
        value: 0,
        label: "All Company Directory",
      },
    ];

    _data.forEach((item) => {
      categories.push({
        value: item.tid,
        label: item.title,
      });
    });

    setCategories(categories);
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  const handleCategorySelection = (value) => {
    setIsLoading(true);

    setIsError(false);
    setCurrentPage(1);
    setSelectedCategory(parseInt(value, 10));
    setSelectedCategoryItem(value);
    setCategoryOpen(false);
  };

  // Pagination component
  const renderPagination = () => {
    if (totalItems <= pageSize) {
      return null;
    } else {
      return (
        <Pagination
          owner="client"
          containerClasses={`creator-pagination d-flex justify-content-center align-items-center mt-4`}
          previousDisabled={currentPage === 1 ? true : false}
          nextDisabled={data.length < pageSize ? true : false}
          currentPage={currentPage}
          onPrevious={() => {
            onPaginate(currentPage - 1);
          }}
          onNext={() => {
            onPaginate(currentPage + 1);
          }}
        />
      );
    }
  };

  const getCategoryName = (id) => {
    let categoryName = categories.find((e) => e.value === id);
    return categoryName ? categoryName.label : "";
  };

  const dataComponent = () => {
    if (typeof data !== "undefined" && data.length > 0) {
      return (
        <Row>
          {data.map((item, index) => (
            <CompanyDirectoryItem item={item} contentType="company_directory" />
          ))}
        </Row>
      );
    } else {
      return <NoData activeIndex={activeIndex} />;
    }
  };

  // Updates content counter
  const updateRemoteCounter = () => {
    const updateData = {
      contentType: contentType,
      acknowledgedCount: counter?.contentCounts?.company_directory || 0,
    };

    patchNode(`${serverUrl}/api/v2/counter`, updateData, user.csrf_token)
      .then((response) => {
        if (response.data) {
          dispatch({ type: "UPDATE_COUNTER", payload: response.data });
        }
      })
      .catch((error) => {
        // console.log('error: ', error);
      });
  };

  return (
    <>
      {isError ? (
        <Error status={errorStatus} message={errorMessage} />
      ) : (
        <main className="company-directory screen">
          <PageHeader
            pageName="Company Directory"
            filters={true}
            owner="client"
            pageNameColor="clientText.dark"
            categorySelector={
              <FormControl variant="standard" fullWidth size="small">
                <InputLabel sx={{ fontSize: 14 }}>Company Directory</InputLabel>
                <Select
                  labelId="company-directory-category-select-label"
                  id="company-directory-category-select"
                  value={selectedCategoryItem}
                  label="Company Directory"
                  open={categoryOpen}
                  onClose={() => setCategoryOpen(false)}
                  onOpen={() => setCategoryOpen(true)}
                  input={
                    <Input
                      sx={{
                        fontSize: 14,
                        "&.MuiInput-underline:before": {
                          borderBottomColor: "#d2d0cf",
                        },
                      }}
                      label="Company Directory"
                    />
                  }
                >
                  {categories.map((category, index) => {
                    return (
                      <MenuItem
                        key={`company-directory-category-${category.value}`}
                        value={category.value}
                        onClick={() => {
                          handleCategorySelection(category.value);
                        }}
                      >
                        {decodeHtmlEntities(category.label)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            }
            getContent={() => {
              setActiveIndex(0);
              setIsLoading(true);
              getContent(1);
            }}
            activeTabIndex={activeIndex}
          />
          {isLoading ? (
            <CompanyappLoader />
          ) : (
            <>
              {dataComponent()}
              {renderPagination()}
            </>
          )}
        </main>
      )}
    </>
  );
}
