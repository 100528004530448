import React, { useEffect, useState, useMemo } from 'react';

// Modules
import * as editorHTML from 'editorjs-html';

// App
import { serverUrl, filesystem_root, subdomain, proxy } from '../../../../../config';
import { jsonParser, getThemeJson, processObject, isSafari } from '../../../../../utils/helpers';
import { default_reveal } from '../../../../../core/defaultReveal';
import ImageGallery from '../../imageGallery';
import FullscreenImageGallery from '../../fullscreenImageGallery';
import BespokeImageGallery from '../../bespokeImageGallery';
import AudioPlayer from '../../../../../partials/audioPlayer';
import { alignment, animation, color, dimension, blockBgColor, headingToggle, overlayTool, draggable } from '../../../../../core/editorOptions';
import { backgroundImageURIGenerator } from '../../../../../core/backgroundImageURIGenerator';

// UI components
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from '@mui/material';
import contactImgDefault from '../../../../../assets/contact-img-default.jpg';
import { AccordionArrow, HotelLocation, ReduceIcon, CloseIcon } from '../../../../../editor/icons/icons';

window.flipCard = function (element) {
  if (!element.classList.contains('flipped')) {
    element.classList.add('flipped');

    setTimeout(function () {
      element.classList.remove('flipped');
    }, 5000);
  } else {
    element.classList.remove('flipped');
  }
}

window.fullPic = function (element) {
  if (!element.classList.contains('full-pic') && element.getAttribute('data-block') !== '1') {
    element.classList.add('full-pic');

    setTimeout(function () {
      element.classList.remove('full-pic');
    }, 5000);
  } else {
    element.classList.remove('full-pic');
  }
}

window.pillarToggle = function (element) {
  const target = element.parentElement.parentElement.parentElement.parentElement
  if (target.classList.contains('active')) {
    target.classList.remove('active');
  } else {
    target.classList.add('active')
  }
}

window.textToggle = function (element) {
  const target = element.parentElement
  if (target.classList.contains('active')) {
    target.classList.remove('active');
  } else {
    target.classList.add('active')
  }
}

window.hideModal = function (modalId) {
  const target = document.querySelector(`#${modalId}`);
  target.classList.remove('show');
}

window.showModal = function (modalId) {
  const target = document.querySelector(`#${modalId}`);
  if (target && !target.classList.contains('show')) {
    target.classList.add('show');
  }
}

window.imgTxtOverlayToggle = function (element, variant) {
  const container = element.closest('.content_block.image-text-overlay');
  if (variant === 'close') {
    container.classList.remove('show');
  } else if (variant === 'open') {
    if (!container.classList.contains('show')) {
      container.classList.add('show');
    }
  } else if (variant === 'toggle') {
    if (!container.classList.contains('show')) {
      container.classList.add('show');
    } else {
      container.classList.remove('show');

    }
  }
}

const Iframe = (props) => {
  const isLibrary = parseInt(props.slide.initial_presentation) !== parseInt(props.presentation_id) ? true : false;
  let src = `${proxy}${props.slide.slide_html_path}`;

  if (isLibrary) {
    src = `${proxy}/${subdomain}/presentations/${props.slide.initial_presentation}/${props.slide.initial_presentation}/${props.id}/${props.id}.html`;

    if (props.slide.field_migration_id) {
      src = `${proxy}/${subdomain}/presentations/${props.slide.initial_presentation}/${props.slide.initial_presentation}/${props.id}.html`;
    }
  } else {
    if (props.live) {
      src = `${proxy}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${props.id}/${props.id}.html`;
      if (props.slide.field_migration_id) {
        src = `${proxy}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${props.id}.html`;
      }
    }
  }

  props.onTest(src);

  return useMemo(() => {
    return <iframe id={`frame-${props.id}`} className='advanced-slide' title={`Advanced Slide ${props.id}`} data-src={src} />;
  }, [props.id, src]);
};

const CountryMapIframe = (props) => {
  let theme_json = props.theme_json;
  let themeDirectory = props.themeDirectory;
  let presentation_id = props.presentation_id;
  let json = props.json;
  let pinSrc;
  let src;
  let blockData = props.blockData || json.blocks.find((e) => e.type === 'countryMap')?.data;
  let mapData;

  // Get the current URL of the page
  const currentUrl = window.location.href;

  // Parse the query string from the URL
  const urlParams = new URLSearchParams(currentUrl.split('?')[1]);
  const hash = urlParams.get('hash') ? urlParams.get('hash') : null;

  if (blockData) {
    mapData = blockData.mapData;
  }
  let apiKey = theme_json?.googleApiKey;
  if (apiKey) {
    pinSrc = `${themeDirectory}/dist/images/icons/${theme_json?.icons?.pin}`;

    if (props.live) {
      pinSrc = `${themeDirectory}/dist/images/icons/${theme_json?.icons?.pin}`;
    }

    let params = `key=${apiKey}&pin=${pinSrc}&presentation=${presentation_id}&viewMode=${props.live ? 'view' : 'preview'}&sub=${subdomain}`;

    if (hash) {
      params = `${params}&hash=${hash}`;
    }

    src = `/country-map.html?${params}`;
  }

  return useMemo(() => {
    if (src) {
      return <iframe title={`map-slide`} id={`country-map-frame-${props.slide.id}`} src={`${src}`} onLoad={() => {
        document.querySelector(`#country-map-frame-${props.slide.id}`).contentWindow.postMessage({ mapData: mapData }, '*');
      }} />
    }
    else {
      return (
        <div className='d-flex h-100 justify-content-center align-items-center'>
          <b>Map can't be displayed - API key error</b>
        </div>
      )
    }
  }, [mapData, props.slide.id, src]);
}

const CustomLayoutCountryMap = (props) => {
  const countryMapBlock = props.json.blocks.find((e) => e.type === 'countryMap')
  if (countryMapBlock) {
    const blockData = countryMapBlock?.data;
    return (
      <div className='map-wrapper' style={parseStyleString(draggable(countryMapBlock))}>
        <div className='map'>
          <div className='map-container'>
            <CountryMapIframe
              blockData={blockData}
              theme_json={props.theme_json}
              themeDirectory={props.themeDirectory}
              presentation_id={props.presentation_id}
              live={props.live}
              json={props.json}
              slide={{ id: props.slide.id }}
            />
          </div>
        </div>
      </div>
    );
  }
  else {
    return '';
  }
}

const parseStyleString = (styleString) => {
  return styleString.split(';').reduce((styleObject, styleProperty) => {
    if (styleProperty) {
      const [property, value] = styleProperty.split(':').map(item => item.trim());
      const camelCaseProperty = property.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
      styleObject[camelCaseProperty] = value.replace(/['"]/g, ''); // remove quotes
    }
    return styleObject;
  }, {});
};

export default function Slide(props) {
  const [contentCount, setContentCount] = useState(0);
  const [hasImage, setHasImage] = useState(false);
  const [hasSubtitle, setHasSubtitle] = useState(false);

  // slide contents
  const [isSlideEmpty, setSlideEmpty] = useState(false);
  const [iframeError, setIframeError] = useState(false);

  // galleries
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState('');

  // text-toggle layout
  const [toggleActive, setToggleActive] = useState(false);

  // hotel-map data
  const [mapData, setMapData] = useState({});

  // memoized
  const json = useMemo(() => (jsonParser(props.slide.slide_json)), [props.slide.slide_json]);
  const theme_json = useMemo(() => (getThemeJson(props.slide.theme.label)), [props.slide.theme.label]);
  const themeDirectory = useMemo(() => (
    props.live
      ? `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/assets/themes/${props.slide?.theme?.label}`
      : `${filesystem_root}/${subdomain}/themes/${props.slide?.theme?.label}`
  ), [props.live, props.presentation_id, props.slide?.theme?.label]);

  if (!json?.blocks) {
    json.blocks = [];
  }

  if (!json.reveal) {
    json.reveal = default_reveal;
  }

  json.blocks.map(obj => processObject({ ...obj }));

  const checkEmpty = (data) => {
    if (data.trim().length === 0) {
      return true;
    }
    else return false;
  }

  function titleParser(block) {
    return checkEmpty(block?.data?.text) ? '' : `<h1 class="slide-title ${animation(block)} ${alignment(block)}" style="${color(block)}; ${draggable(block)}">${block?.data?.text}</h1>`;
  }

  function subtitleParser(block) {
    return checkEmpty(block?.data?.text) ? '' : `<h2 class="slide-subtitle ${animation(block)} ${alignment(block)}" style="${color(block)}; ${draggable(block)}">${block?.data?.text}</h2>`;
  }

  function blockHeadingParser(block) {
    return checkEmpty(block?.data?.text) ? '' : `<h4 class="slide-block-heading content_block ${animation(block)} ${alignment(block)}" style="${color(block)}">${block?.data?.text}</h4>`;
  }

  function listParser(block) {
    function createItems(block, items) {
      let itemsString = '';

      items?.forEach(element => {
        if (!checkEmpty(element.content) || element.items.length !== 0) {
          itemsString = itemsString + `<li style="${color(block)}" class="${animation(block)} ${color(block) ? "li-custom" : ""}">${element.content} ${element.items.length !== 0 ? createItems(block, element.items) : ''}</li>`;
        }
      });

      return block?.data?.style === 'ordered' ? `<ol>${itemsString}</ol>` : `<ul> ${itemsString}</ul>`;
    }

    return (`
      <div class="list_block content_block ${alignment(block)}" style="${draggable(block)}">
        ${createItems(block, block?.data?.items)}    
      </div>
    `);
  }

  function tableParser(block) {
    let rowContainer = '';

    if (json?.reveal?.slideLayout?.id === 'healthcare-meeting-types' && theme_json?.layouts.find(o => { return o.id === 'healthcare-meeting-types' })) {

      let icons = json?.reveal?.slideLayout?.icons;

      if (theme_json && theme_json?.layouts) {
        let slideLayoutObj = theme_json?.layouts.find(o => { return o.id === 'healthcare-meeting-types' });

        icons = slideLayoutObj.icons;
      }

      block.data.content.forEach((row) => {
        let rowItems = '';
        if (row[1]) {
          const icon = icons.filter(obj => {
            return obj.name === row[0];
          });

          if (icon[0]?.name === row[0]) {
            rowItems = `
              <div class="tc-cell">
                <div class="item-icon">${icon[0]?.icon}</div>
                <div class="">${row[0]}</div>
              </div>
              <div class="tc-cell">${row[1]}</div>`;
          } else {
            rowItems = `
              <div class="tc-cell">
                <div class="">${row[0]}</div>
              </div>
              <div class="tc-cell">${row[1]}</div>`;
          }

          rowContainer = rowContainer + `<div class="tc-row">${rowItems}</div>`;
        }
      });
    } else if (json?.reveal?.slideLayout?.id === 'table-of-contents') {

      const currentUrl = window.location.href;
      const urlParams = new URLSearchParams(currentUrl.split('?')[1]);
      const hash = urlParams.get('hash') ? urlParams.get('hash') : null;

      block.data.content.forEach(row => {
        let rowItems = '';
        let slideNum = 1
        let currentDomain = window.location.origin;
        let pathName = window.location.pathname.split('/')
        row.forEach(cell => {
          if (cell.toLowerCase().match(/slide\s\d+/)) {
            slideNum = cell.match(/\d+/);
          }
          rowItems = rowItems + `<div class="tc-cell">${cell}</div>`;
        })

        let link = `${currentDomain}/${pathName[1]}/${pathName[2]}/${pathName[3]}`;

        if (hash) {
          const hashSplitted = hash.split('#');
          link = `${link}?hash=${hashSplitted[0]}`;
        }

        rowContainer = rowContainer + `<div class="tc-row"><a href=${link}#/${slideNum}>${rowItems}</a></div>`;
      });
    }
    else {
      // if (json?.reveal?.slideLayout?.id === 'data-table') {
      let dataTableObj = theme_json?.layouts.find(o => { return o.id === 'data-table' });
      let icons = dataTableObj?.icons;
      block.data.content.forEach(row => {
        let rowItems = '';
        row.forEach(cell => {
          if (cell.toLowerCase().match(/\?(.*)(?=\?)/) && icons) {
            let iconName = cell.toLowerCase().match(/\?(.*)(?=\?)/);
            let iconSVG = icons.find(el => el.name === iconName[1]);
            if (iconName.length > 0 && iconSVG) {
              rowItems = rowItems + `<div class="tc-cell"><div class="${iconSVG.name}-icon icon-container">${iconSVG.icon}</div></div>`;
            }
            else {
              rowItems = rowItems + `<div class="tc-cell">${cell}</div>`;
            }
          }
          else {
            rowItems = rowItems + `<div class="tc-cell">${cell}</div>`;
          }
        })
        rowContainer = rowContainer + `<div class="tc-row">${rowItems}</div>`;
      });
      // }
    }

    let headingClass = block?.data?.withHeadings ? 'tc-table--heading' : '';

    return `<div class="tc-table ${headingClass} content_block ${animation(block)} ${alignment(block)}" style="${color(block)} ${draggable(block)}">${rowContainer}</div>`;
  }

  function quoteParser(block) {
    const quote = block?.data?.text !== '' ? `<blockquote class="${animation(block)} ${alignment(block)} ${color(block) ? 'quote-custom' : ''}" style="${color(block)}">${block?.data?.text}</blockquote>` : '';
    // const author = block?.data?.caption !== '' ? `<div class="${animation(block)} ${alignment(block)} caption" style="${color(block)}">${block?.data?.caption}</div>` : '';

    return checkEmpty(block?.data?.text) ? '' : `
      <div class="quote-container content_block">
        <div class="quote-container">
          ${quote}
        </div>
      </div>`;
  }

  function bodyParser(block) {
    return checkEmpty(block?.data?.text) ? '' :
      `<div class="body_block content_block" style="${draggable(block)}">
        <p class="${animation(block)} ${alignment(block)}" style="${color(block)}">${block?.data?.text}</p>
      </div>`;
  }

  function ruleParser(block) {
    return `<div class="rule_block content_block ${animation(block)} ${alignment(block)}" style="${draggable(block)}" ><hr style="${color(block)}" /></div>`;
  }

  function imageParser(block) {
    let url = block?.data?.url;

    if (url === '') {
      return '';
    }

    let imageBlocks = [];

    json?.blocks.forEach((_block) => {
      if (_block.type === 'image') {
        imageBlocks.push(_block);
      }
    });

    let imageIndex = 0;
    imageBlocks.forEach((_block, index) => {
      if (_block.id === block.id) {
        imageIndex = index + 1;
      }
    });

    if (props.live) {
      url = `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${url}`;
    }

    const imageElement = `<img data-src="${url}" data-mid="${block?.data?.mid} alt="image" style="${overlayTool(block) ? `opacity: ${overlayTool(block).opacity}` : ''}"/>`;
    const x2 = `<img class="x2" data-src="${url}" data-mid="${block?.data?.mid} alt="image" style="${overlayTool(block) ? `opacity: ${overlayTool(block).opacity}` : ''}"/>`;

    if (json.reveal.slideLayout.id === 'one-row-image') {
      return url !== '' ? `
        <div data-block="${imageIndex}" class="image_block content_block ${animation(block)} ${alignment(block)}">
          ${imageElement}
          ${x2}
        </div>` : '';
    } else {
      return url !== '' ?
        `<div data-block="${imageIndex}" class="image_block content_block ${animation(block)} ${alignment(block)}" ${json.reveal.slideLayout.id === 'at-a-glance' ? 'onclick="fullPic(this);"' : ''} style="${overlayTool(block) ? `background-color: ${overlayTool(block).backgroundColor};` : ''} ${draggable(block)}">
        <button class="full-pic-close-btn" onclick="fullPic(this)"> ${ReduceIcon}</button>
        ${imageElement}
      </div>`
        :
        '';
    }
  }

  function inlineImageParser(block) {
    let url = block?.data?.url;
    if (url === '') {
      return '';
    }

    let imageBlocks = [];

    json?.blocks.forEach((_block) => {
      if (_block.type === 'image') {
        imageBlocks.push(_block);
      }
    });

    let imageIndex = 0;
    imageBlocks.forEach((_block, index) => {
      if (_block.id === block.id) {
        imageIndex = index + 1;
      }
    });

    if (props.live) {
      url = `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${url}`;
    }

    const imageElement = `<img style="${dimension(block)}" data-block="${imageIndex}" data-src="${url}" src="${url}" data-mid="${block?.data?.mid} alt="image" />`;
    return url !== '' ? `<div class="inline_image_block content_block ${animation(block)} ${alignment(block)}">${imageElement}</div>` : '';
  }

  function ancillaryParser(block) {
    const element = checkEmpty(block?.data?.heading) || checkEmpty(block?.data?.body) ? '' : `
      <div class="ancillary-content-container">
        <span class="ancillary-heading ancillary-content" style="${color(block)}">
          ${block?.data?.heading}
        </span>
        <span class="ancillary-body ancillary-content" style="${color(block)}">
          ${block?.data?.body}
        </span>
      </div>
    `;

    return `<div class="ancillary-container ancillary_block ${animation(block)} ${alignment(block)}">${element}</div>`;
  }

  function iconParser(block) {
    const iconColor = block?.data?.color;
    let styles;

    if (iconColor) {
      styles = `color: ${iconColor}`;
    }

    const svg = `<div class="icon-container" style="${styles}">${block?.data?.icon}</div>`
    return `<div class="icon_block content_block ${animation(block)} ${alignment(block)}">${svg}</div>`;
  }

  function videoParser(block) {
    let url = block?.data?.url;

    if (url === '') {
      return '';
    }

    if (props.live) {
      url = `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${url}`;
    } else {
      url = `${serverUrl}/api/v2/assets?mid=${block.data.mid}`;
    }

    let poster;

    if (block?.data?.poster) {
      if (block?.data.external) {
        poster = block.data.poster;
      } else {
        poster = `${serverUrl}/api/video/thumbnail?mid=${block.data.mid}`;
      }
    }

    const videoElement = `<video class="plyr" data-src="${url}" data-mid="${block?.data?.mid}" data-poster="${poster}" playsinline  controls><source src="${url}" type="video/mp4"></video>`;

    return `<div class="video_block content_block ${animation(block)} ${alignment(block)}" style="${draggable(block)}">${videoElement}</div>`;
  }

  function audioParser(block) {
    let url = block?.data?.url;

    if (url === '') {
      return '';
    }

    if (props.live) {
      url = `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${url}`;
    }

    const audioElement = `<audio data-src="${url}" data-mid="${block?.data?.mid}" controls />`;
    return `<div class="audio_block content_block ${animation(block)} ${alignment(block)}">${audioElement}</div>`;
  }

  function contactParser(block) {
    const address = block?.data?.address?.length > 0 ? block?.data?.address : null;
    const email = block?.data?.email?.length > 0 ? block?.data?.email : null;
    const linkedin = block?.data?.linkedin?.length > 0 ? block?.data?.linkedin : null;
    const name = block?.data?.name?.length > 0 ? block?.data?.name : null;
    const phone = block?.data?.phone?.length > 0 ? block?.data?.phone : null;
    const title = block?.data?.title?.length > 0 ? block?.data?.title : null;
    const twitter = block?.data?.twitter?.length > 0 ? block?.data?.twitter : null;
    let url = block?.data?.url;
    let addressElement = ``;
    let emailElement = ``;
    let linkedinElement = ``;
    let nameElement = ``;
    let phoneElement = ``;
    let titleElement = ``;
    let twitterElement = ``;
    let defaultContactImg = contactImgDefault

    if (props.live) {
      url = url !== '' ? `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${url}` : `${defaultContactImg}`;
    }
    else {
      url = url !== '' && url !== filesystem_root ? url : `${defaultContactImg}`;
    }

    const imageElement = `<img class="cdx-contact" data-src="${url}" src="${url}" data-mid="${block?.data?.mid} alt="image" />`;

    if (address) {
      addressElement = `<a target="_blank" class="cdx-contact-input address" href="https://maps.google.com/?q=${address}">${address}</a>`;
    }

    if (email) {
      emailElement = `<a target="_blank" class="cdx-contact-input contact-email" href="mailto:${email}">${email}</a>`;
    }

    if (linkedin) {
      linkedinElement = `<a target="_blank" class="cdx-contact-input linkedin" href="${linkedin}">Connect on LinkedIn</a>`;
    }

    if (name) {
      nameElement = `<h4 class="cdx-contact-input name">${name}</h4>`;
    }

    if (phone) {
      phoneElement = `<a target="_blank" class="cdx-contact-input contact-number" href="tel:${phone}">${phone}</a>`
    }

    if (title) {
      titleElement = `<h6 target="_blank" class="cdx-contact-input title">${title}</h6>`;
    }

    if (twitter) {
      twitterElement = `<a target="_blank" class="cdx-contact-input twitter" href="https://twitter.com/${twitter}">@${twitter}</a>`;
    }

    return `
      <div class="contact-block content_block ${animation(block)} ${alignment(block)}">
        <div class="cdx-contact-container">
            ${imageElement}
            <div class="cdx-contact-info-container">
              ${nameElement}
              ${titleElement}
              ${phoneElement}
              ${emailElement}
              ${linkedinElement}
              ${twitterElement}
              ${addressElement}
          </div>
        </div>
      </div>
    `;
  }

  function embedParser(block) {
    const element = `<div class="embed-container"><div class="embed">${block.data.code}</div></div>`;
    return `<div class="embed content_block ${animation(block)} ${alignment(block)}" style="${draggable(block)}">${element}</div>`;
  }

  function mapParser(block) {
    let html;

    const apiKey = theme_json?.googleApiKey;

    if (apiKey) {
      let pinSrc = `${themeDirectory}/dist/images/icons/${theme_json?.icons?.pin}`;

      if (props.live) {
        pinSrc = `${themeDirectory}/dist/images/icons/${theme_json?.icons?.pin}`;
      }

      const label = encodeURIComponent(block?.data?.label);
      const params = `key=${apiKey}&lat=${block?.data?.latitude}&lng=${block?.data?.longitude}&label=${label}&pin=${pinSrc}&zoom=${block?.data?.zoomLevel ?? 16}`;
      html = `<iframe data-src="/map.html?${params}" />`;
    } else {
      html = '<div class="d-flex h-100 justify-content-center align-items-center"><b>Map can\'t be displayed - API key error</b></div>';
    }

    const tag = document.createElement('div');
    tag.classList.add('w-100');
    tag.classList.add('h-100');
    tag.setAttribute('id', `map-${props.slide.id}`);

    tag.innerHTML = html;

    const container = document.createElement('div');
    container.appendChild(tag);
    const data = container.innerHTML || '';

    return `
      <div class="map">
        <div class="map-container ${animation(block)} ${alignment(block)}">${data}</div>
      </div>`;
  }

  function clientParser(block) {
    let url = block?.data?.url;
    let image = '';

    if (url !== '') {
      if (props.live) {
        url = `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${url}`;
      }

      image = `<img data-src="${url}" alt="image" />`;
    }

    const element =
      `<div class="cdx-client" onclick="flipCard(this);">
        <div class="front">${image}</div>
        <div class="back"><p>${block?.data?.text}</p></div>
      </div>`;

    return `<div class="client content_block ${animation(block)} ${alignment(block)}">${element}</div>`;
  }

  function pointParser(block) {
    let url = block?.data?.url;
    let image = '';
    let altIndexText = ''

    if (url !== '') {
      if (props.live) {
        url = `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${url}`;
      }

      image = `<img data-src="${url}" alt="image" />`;
    }

    if (block?.data?.altIndex) {
      altIndexText = `<h1 class="point-alternate" style="${color(block)}">${block?.data?.altIndexText}</h1>`
    }

    const element = `
      <div class="point-img-container ${block?.data?.altIndex ? 'alt-index' : ''}">
        ${block?.data?.altIndex ? altIndexText : image}
      </div>
      <div class="point-content-container">
        <h4 class="point-heading" style="${color(block)}">
          ${block?.data?.heading}
        </h4>
        <p class="point-body" style="${color(block)}">
          ${block?.data?.body}
        </p>
      </div>
    `;

    return `<div class="point-container point_block ${animation(block)} ${alignment(block)} ${block?.data?.orientation ? 'column' : ''}" style="${draggable(block)}">${element}</div>`;
  }

  function pillarParser(block) {
    let url = block?.data?.url;
    let imgElement = '';

    if (url !== '') {
      if (props.live) {
        url = `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${url}`;
      }

      imgElement = `<img data-src="${url}" alt="" class="media-wrapper"/>`;
    }
    const headingElement = `<div class="heading-container"> <h4 class="heading-title">${block.data.heading}</h4></div>`
    const subHeadingElement = block.data.subHeading ? `<div class="sub-heading-container"> <h6 class="heading-sub-title">${block.data.subHeading}</h6></div>` : ''
    const bodyElement = `<div class="body-container"> <div class="body-redactor"><p class="body">${block.data.body}</p></div></div>`
    const accordToggleBtn = `<button class="accord-toggle" onclick="pillarToggle(this);">${AccordionArrow}</button>`
    const headerContainer = `
    <div class="header-container">
      <div class="header-redactor ${headingToggle(block)}"
        ${!block.data.subHeading && json.reveal.slideLayout.id === 'four-column-accordion-image' ? 'style="justify-content: flex-end;"' : ''}
      >
        ${headingElement}
        ${subHeadingElement}
        ${accordToggleBtn}
      </div>
    </div>`
    const element = `
      <div class="content_block pillar_block">
        ${imgElement}
        <div class="text-container ${blockBgColor(block)}">
          <div class="text-redactor">
            ${headerContainer}
            ${bodyElement}
          </div>
        </div>
      </div>
      `

    return element
  }

  function layoutParser(block) {
    let layoutEditorBlocks = block.data.itemContent[1]
    const toggleBtn = `<button class="toggle-btn" onclick="textToggle(this);">${AccordionArrow}</button>`
    return `
      <div class="content_block layout_block">
        ${json?.reveal?.slideLayout?.id === 'one-column-text-toggle' ? toggleBtn : ''}
        <div class="layout_block_redactor">
          ${editorLayoutParser.parse(layoutEditorBlocks).join('')}
        </div>
      </div>`
  }

  function popupParser(block) {
    const popupHeading = `<h3 class="popup-heading">${block?.data?.heading}</h3>`;
    const contentType = block?.data?.contentType;
    const listType = block?.data?.listType;
    let listItems = '';

    if (contentType === 'list') {
      if (block?.data?.list.length > 0) {
        block?.data?.list.forEach((e) => {
          listItems = listItems + `<li class="popup-list-item">${e}</li>`
        })
      }
    }

    let list = listType === 'ordered' ? `<ol class="popup-list">${listItems}</ol>` : `<ul class="popup-list">${listItems}</ul>`

    return `
      <div id="modal-${props.slide.id}-${block?.data?.modalId}" class="modal_block content_block popup-modal-container">
        <div class="popup-backdrop"></div>
        <div class="popup-modal">
          <div class="popup-modal-heading-container">
            ${popupHeading}
            <button class="popup-close" onclick="hideModal('modal-${props.slide.id}-${block?.data?.modalId}')">${CloseIcon}</button>
          </div>
          <div class="popup-modal-content-container">
            <div class="popup-modal-content">
              ${contentType === 'text' ?
        `<p class="popup-body">${block?.data?.body}</p>` :
        `${list}`
      }
            </div>
          </div>
        </div>
      </div>`
  }

  function buttonGroupParser(block) {
    const blockData = block.data.buttonData;
    const buttonItem = (e) => {
      return `
      <div class="btn-item-container">
        <a href="${e.url}" class="btn-item-link-wrapper" target="_blank" rel="noopener noreferrer">
          <button class="btn-item" style="width: ${block.data.buttonWidth}px;">${e.text}</button>
        </a>
      </div>
      `
    }

    let buttons = '';

    blockData.forEach((e) => {
      buttons = buttons + buttonItem(e);
    });

    return `
    <div class="content_block button-group_block"  style="${draggable(block)}">
      <div class="btn-group-container">
        ${buttons}
      </div>
    </div>
    `
  }

  function locationHeaderParser(block) {
    let data = block?.data
    let locationIcon = `
      <div class="location-pin-icon">
        ${HotelLocation}
      </div>
    `;
    let hotelCountry = `
      <h6 class="hotel-country">
        ${data.country}
      </h6>
    `;
    let hotelCity = `
    <h6 class="hotel-city">
      ${data.city}
    </h6>
  `;
    let hotelName = `
    <h6 class="hotel-name">
      ${data.hotelName}
    </h6>
  `;
    let hotelHeading = `
    <div class="hotel-heading-container">
      <div class="hotel-heading-content-container">
        ${locationIcon}
        ${hotelCountry}
        ${hotelCity}
        ${hotelName}
      </div>
    </div>
    `;

    return hotelHeading
  }

  function hotelCapacityParser(block) {
    let data = block?.data

    let makeGroup = (value, label, groupName) => {
      return `
        <div class="hotel-capacity-group ${groupName}-group">
          <div class="hotel-capacity-value">
            ${value}
          </div>
          <div class="hotel-capacity-label">
            ${label}
          </div>
        </div>
      `
    }

    let hotelCapacity = `
    <div class="hotel-capacity-container">
      <div class="hotel-capacity-content-container">
      ${makeGroup(data.guestRoom, 'GUEST<br>ROOMS', 'guest-rooms')}
      ${makeGroup(data.meetingRooms, 'MEETING<br>ROOMS', 'meeting-rooms')}
      ${makeGroup(data.maxCap, 'MAX<br>CAP', 'max-cap')}
      ${makeGroup(data.exhibitSpace, 'EXHIBITION<br>SPACE SQM', 'exhibit-space')}
      ${makeGroup(data.suites, 'SUITES', 'suites')}
      ${makeGroup(data.familyRooms, 'FAMILY<br>ROOMS', 'family-rooms')}
      ${makeGroup(data.restaurants, 'RESTAURANTS', 'restaurants')}
      ${makeGroup(data.bars, 'BARS', 'bars')}
      ${makeGroup(data.spa, 'SPA', 'spa')}
      ${makeGroup(data.gym, 'GYM', 'gym')}
      ${makeGroup(data.pool, 'POOL', 'pool')}
      ${makeGroup(data.parking, 'PARKING', 'parking')}
      ${makeGroup(data.freeWifi, 'FREE<br>WIFI', 'free-wifi')}
      ${makeGroup(data.breakfastBuffet, 'BREAKFAST<br>BUFFET', 'breakfast-buffet')}
      ${makeGroup(data.expressCheckout, 'EXPRESS<br>CHECK-OUT', 'express-checkout')}
      </div>
    </div>
    `;

    return hotelCapacity
  }

  function locationFooterParser(block) {
    let data = block?.data;
    let locationContainer = `
      <div class="hotel-footer-location-container">
        <p class="hotel-footer-location-label">
          LOCATION:
        </p>
        <p class="hotel-footer-location">
          ${data.location.text}
        </p>
      </div>
    `
    let hotelUrl = `
      <a href="${data.hotelUrl}" class="hotel-footer-url">VISIT HOTEL WEBSITE</a>
    `
    let locationFooter = `
      <div class="hotel-footer-container">
        <div class="hotel-footer-content-container">
          ${locationContainer}
          ${hotelUrl}
        </div>
      </div>
    `

    return locationFooter
  }

  function hotelTextOverlayParser(block) {
    let logo = block.data.logo.svg !== "" ? `<div class="hotel-logo-container">${block.data.logo.svg}</div>` : ""
    let heading = `<h4 class="overlay-heading">${block.data.heading}</h4>`
    let body = `<p class="overlay-body">${block.data.body}</p>`

    let container = `<div class="content_block hotelTextOverlay"><div class="hotel-text-overlay-content-container">${logo}${heading}${body}</div></div>`
    return container
  }

  function imageTextOverlayParser(block) {
    let heading = block.data.heading === '<br>' ? '' : block.data.heading;
    let body = block.data.body === '<br>' ? '' : block.data.body;
    const processUrl = (_url) => {
      let url = _url;

      if (url === '') {
        return '';
      }

      if (props.live) {
        url = `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${url}`;
      }

      return url;
    }



    let imageTextOverlayBlocks = [];

    json?.blocks.forEach((_block) => {
      if (_block.type === 'imageTextOverlay') {
        imageTextOverlayBlocks.push(_block);
      }
    });

    let itemIndex = 0;
    imageTextOverlayBlocks.forEach((_block, index) => {
      if (_block.id === block.id) {
        itemIndex = index + 1;
      }
    });

    let showOverlayBtn = `<button class="img-txt-overlay-toggle-btn" onclick="imgTxtOverlayToggle(this, '${block.data.toggleOption ? 'toggle' : 'open'}')">${block.data.heading}<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"> <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" /> </svg></button>`
    let contentContainer = `
    <div class="img-txt-overlay-content-container">
    ${block.data.toggleOption ? '' : `
      <button class="img-txt-overlay-content-close-btn" onclick="imgTxtOverlayToggle(this, 'close')">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
          <path
            d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
        </svg>
      </button>
      `}
      <h4 class="img-txt-overlay-content-title">${heading}</h4>
      <p class="img-txt-overlay-content-body">${body}</p>
    </div>`
    const imageContainer = `<div class="img-txt-overlay-image-container">
      <img src="${processUrl(block.data?.images?.[0]?.url)}" alt="" class="img-txt-overlay-primary-image">
      ${block.data.secondaryImage ?
        `<img src="${processUrl(block.data?.images?.[1]?.url)}" alt="" class="img-txt-overlay-secondary-image">` : ''
      }
    </div>`
    if(json.reveal.slideLayout.id=== 'static-gallery'){
      if(heading === '' && body === ''){
        showOverlayBtn = '';
        contentContainer = '';
      }
    }
    const container = `
    <div class="content_block image-text-overlay ${block.data.secondaryImage ? 'has-secondary' : ''}" data-block="${itemIndex}">
      ${showOverlayBtn}
      ${contentContainer}
      ${imageContainer}
    </div>`

    return container;
  }

  function captionedImageParser(block) {
    let url = block?.data?.url;

    if (url === '') {
      return '';
    }

    let imageBlocks = [];

    json?.blocks.forEach((_block) => {
      if (_block.type === 'captionedImage') {
        imageBlocks.push(_block);
      }
    });

    let imageIndex = 0;
    imageBlocks.forEach((_block, index) => {
      if (_block.id === block.id) {
        imageIndex = index + 1;
      }
    });

    if (props.live) {
      url = `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${url}`;
    }

    const imageElement = `<img data-src="${url}" data-mid="${block?.data?.mid} alt="image" style="${overlayTool(block) ? `opacity: ${overlayTool(block).opacity}` : ''}"/>`;
    const caption = `<p class="caption">${block.data.caption}</p>`

    return url !== '' ?
      `<div data-block="${imageIndex}" class="captioned_image_block content_block ${animation(block)} ${alignment(block)}" style="${overlayTool(block) ? `background-color: ${overlayTool(block).backgroundColor};` : ''} ${draggable(block)}">
          ${imageElement}
          ${caption}
        </div>`
      :
      '';
  }

  // editorParser
  const editorParser = editorHTML({
    title: titleParser,
    subtitle: subtitleParser,
    blockHeading: blockHeadingParser,
    list: listParser,
    table: tableParser,
    quote: quoteParser,
    body: bodyParser,
    rule: ruleParser,
    image: imageParser,
    inlineImage: inlineImageParser,
    ancillary: ancillaryParser,
    icon: iconParser,
    video: videoParser,
    audio: audioParser,
    contact: contactParser,
    embed: embedParser,
    client: clientParser,
    point: pointParser,
    pillar: pillarParser,
    layout: layoutParser,
    locationHeader: locationHeaderParser,
    hotelCapacity: hotelCapacityParser,
    locationFooter: locationFooterParser,
    hotelTextOverlay: hotelTextOverlayParser,
    popup: popupParser,
    buttonGroup: buttonGroupParser,
    imageTextOverlay: imageTextOverlayParser,
    captionedImage: captionedImageParser,
    map: () => {
      return '';
    },
    countryMap: () => {
      return '';
    },
    gallery: () => {
      return '';
    }
  });

  // layout block parser
  const editorLayoutParser = editorHTML({
    title: titleParser,
    subtitle: subtitleParser,
    blockHeading: blockHeadingParser,
    list: listParser,
    table: tableParser,
    quote: quoteParser,
    body: bodyParser,
    rule: ruleParser,
    image: imageParser,
    inlineImage: inlineImageParser,
    ancillary: ancillaryParser,
    icon: iconParser,
    video: videoParser,
    audio: audioParser,
    contact: contactParser,
    embed: embedParser,
    client: clientParser,
    point: pointParser,
    pillar: pillarParser,
    layout: layoutParser,
    buttonGroup: buttonGroupParser,
    imageTextOverlay: imageTextOverlayParser,
    captionedImage: captionedImageParser,
    map: () => {
      return '';
    },
    gallery: () => {
      return '';
    }
  });

  // mapParser
  const editorMapParser = editorHTML({
    title: () => { return ''; },
    subtitle: () => { return ''; },
    blockHeading: () => { return ''; },
    list: () => { return ''; },
    table: () => { return ''; },
    quote: () => { return ''; },
    body: () => { return ''; },
    rule: () => { return ''; },
    image: () => { return ''; },
    inlineImage: () => { return ''; },
    ancillary: () => { return ''; },
    icon: () => { return ''; },
    video: () => { return ''; },
    audio: () => { return ''; },
    contact: () => { return ''; },
    embed: () => { return ''; },
    client: () => { return ''; },
    point: () => { return ''; },
    pillar: () => { return ''; },
    popup: () => { return ''; },
    buttonGroup: () => { return ''; },
    imageTextOverlay: () => { return ''; },
    captionedImage: () => { return ''; },
    map: mapParser,
    gallery: () => { return ''; }
  });

  if (!json.reveal.slideTransition) json.reveal.slideTransition = default_reveal.slideTransition;
  if (!json.reveal.slideLogo) json.reveal.slideLogo = default_reveal.slideLogo;
  if (!json.reveal.slideLogoPosition) json.reveal.slideLogoPosition = default_reveal.slideLogoPosition;
  if (!json.reveal.slideLayout) json.reveal.slideLayout = default_reveal.slideLayout;
  if (!json.reveal.slideLayoutScheme) json.reveal.slideLayoutScheme = default_reveal.slideLayoutScheme;
  if (!json.reveal.slideContentPosition) json.reveal.slideContentPosition = default_reveal.slideContentPosition;

  if (!json.reveal.slideBackgroundColor) json.reveal.slideBackgroundColor = default_reveal.slideBackgroundColor;
  if (!json.reveal.slideBackgroundTransition) json.reveal.slideBackgroundTransition = default_reveal.slideBackgroundTransition;
  if (!json.reveal.slideBackgroundImage) json.reveal.slideBackgroundImage = default_reveal.slideBackgroundImage;
  if (!json.reveal.slideBackgroundOpacity) json.reveal.slideBackgroundOpacity = default_reveal.slideBackgroundOpacity;
  if (!json.reveal.slideBackgroundRepeat) json.reveal.slideBackgroundRepeat = default_reveal.slideBackgroundRepeat;
  if (!json.reveal.slideBackgroundPosition) json.reveal.slideBackgroundPosition = default_reveal.slideBackgroundPosition;
  if (!json.reveal.slideBackgroundSize) json.reveal.slideBackgroundSize = default_reveal.slideBackgroundSize;

  if (!json.reveal.slideInnerBackgroundColor) json.reveal.slideInnerBackgroundColor = default_reveal.slideInnerBackgroundColor;
  if (!json.reveal.slideInnerBackgroundImage) json.reveal.slideInnerBackgroundImage = default_reveal.slideInnerBackgroundImage;
  if (!json.reveal.slideInnerBackgroundOpacity) json.reveal.slideInnerBackgroundOpacity = default_reveal.slideInnerBackgroundOpacity;
  if (!json.reveal.slideInnerBackgroundRepeat) json.reveal.slideInnerBackgroundRepeat = default_reveal.slideInnerBackgroundRepeat;
  if (!json.reveal.slideInnerBackgroundPosition) json.reveal.slideInnerBackgroundPosition = default_reveal.slideInnerBackgroundPosition;
  if (!json.reveal.slideInnerBackgroundSize) json.reveal.slideInnerBackgroundSize = default_reveal.slideInnerBackgroundSize;
  if (!json.reveal.archContentAlignment) json.reveal.archContentAlignment = default_reveal.archContentAlignment
  if (!json.reveal.capacityTags) json.reveal.capacityTags = default_reveal.capacityTags;
  if (!json.reveal.slideAutoAnimate) json.reveal.slideAutoAnimate = default_reveal.slideAutoAnimate;

  if (props.slideAudioData) json.reveal.slideAudioData = props.slideAudioData; else json.reveal.slideAudioData = default_reveal.slideAudioData;

  const SectionTitleArchImage = () => {
    let imageSrc = backgroundImageURIGenerator(json.reveal.slideInnerBackgroundImage, props.live, props.slide, props.presentation_id);

    return (
      <div className="image-container">
        <img className="image img-width-0 img-height-0"
          src={imageSrc}
          alt=""
          style={{ filter: `saturate(${json?.reveal?.slideOverlayOpacity === 1 ? 1 : 0}) opacity(${json?.reveal?.slideOverlayOpacity ? json?.reveal?.slideOverlayOpacity : 0.7})` }}
        />

        <div className="colour-filter" style={{ backgroundColor: json.reveal.slideInnerBackgroundColor }}></div>

        <svg width="100%" height="100%" viewBox="0 0 1366 768" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <mask id='mask'>
              <path fill='white' d="M1278.91,768H786c.29-.94,61.53-255.72,18.09-460.58C766.76,131.56,622.58.55,622,0h492c2.19,3.75,141.44,131.92,176.94,298.05,45,210.42-12.94,468.85-12.94,468.85C1278.05,767.23,1279,767.67,1278.91,768Z" />
            </mask>
          </defs>

          <image width="1366" height="768"
            href={imageSrc}
            mask={`${json.reveal.slideInnerBackgroundColor ? 'url(#mask)' : ''}`}></image>
        </svg>
      </div>
    );
  };

  const OneColumnArchImage = () => {
    return (
      <div className='overlay-container'>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
          <path fill="#fff" d="M1104.72,1079.73c-365.62,0-732.66-.09-1098.29,0-5.3,0-6.47-1.16-6.47-6.46Q.2,540,0,6.73C0,1.44,1.13.27,6.43.27c249,.15,618.79,0,867.78,0,.85.78,203.5,184.91,255.93,432.1C1191.2,720.3,1105.12,1078.41,1104.72,1079.73Z" transform="translate(0.04 -0.27)" />
          <path fill="#fff" d="M1565.74.27q174.3,0,348.51,0c4.81,0,5.76,1,5.75,5.76q-.21,534,0,1067.94c0,4.82-.93,5.8-5.75,5.76-38.88-.25-77.75,0-116.66,0,.11-.46-1.21-1.08-1.33-1.54,0,0,81.38-363.24,18.18-659C1764.55,185.69,1568.82,5.54,1565.74.27Z" transform="translate(0.04 -0.27)" />
        </svg>
      </div>
    );
  };

  const OneColumnHalfCircleImage = () => {
    return (
      <div className='overlay-container'>{
        (props?.slide?.theme?.label === "companyapp" || props?.slide?.theme?.label === "Companyapp" || props?.slide?.theme?.label === "bread-creative") ?
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
            <title>full-circle</title>
            <path fill='#fff' stroke='none' d="M0,0V1080H1920V0ZM1440,946c-224,0-405.5-181.55-405.5-405.5S1216.05,135,1440,135s405.5,181.55,405.5,405.5S1664,946,1440,946Z" />
          </svg>
          :
          <svg fill="#fff" stroke="none" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720">
            <path fill="#fff" stroke="none" d="M646,381a831.65,831.65,0,0,0,81.14,339H0V0H729C696.14,67.91,641.92,203.34,646,381Z" />
          </svg>
      }
      </div>
    );
  };

  const ImageWithVerticalGradientOverlay = () => {
    return <div className={`overlay-container alpha-${Math.round(json?.reveal?.slideOverlayOpacity * 10)} ${json?.reveal?.slideOverlayColor} overlay-${json?.reveal?.slideOverlayWidth} ${json?.reveal?.slideContentPosition}`} />
  };

  useEffect(() => {
    let isImageBlock = false;
    let isSubtitleBlock = false;
    let contentIndex = 0;

    if (json.blocks && json.blocks.length > 0) {
      json.blocks.forEach(block => {
        if (block.type === 'image' || block.type === 'inlineImage') {
          isImageBlock = true;
        }

        if (block.type === 'subtitle') {
          isSubtitleBlock = true;
        }

        if (block.type === 'body' || block.type === 'list') {
          contentIndex++;
        }
      });
    } else {
      if (
        json.reveal.slideLogo === '' &&
        json.reveal.slideInnerBackgroundImage === '' &&
        json.reveal.slideBackgroundImage === ''
      ) {
        setSlideEmpty(true);
      }
    }

    setContentCount(contentIndex);
    setHasImage(isImageBlock);
    setHasSubtitle(isSubtitleBlock);
  }, []);

  useEffect(() => {
    if (json?.reveal?.slideLayout?.id === 'image-gallery' || json?.reveal?.slideLayout?.id === 'fullscreen-image-gallery' || json?.reveal?.slideLayout?.id === 'artotel-image-gallery') {
      let newImages = [];
      json.blocks.forEach(block => {
        if (block.type === 'gallery') {
          block?.data?.images?.forEach((image) => {
            newImages.push(image);
          })
        }

        if (block.type === 'title') {
          setTitle(block);
        }

        setImages(newImages);
      });
    }
  }, []);

  useEffect(() => {
    if (props.activeTrigger) {
      props.slideHasAudio(json.reveal.slideAudioData ? true : false)
    }
  }, [props.activeTrigger]);

  useEffect(() => {
    if (json.reveal.slideAudioData) {
      props.slideHasAudioPrompt();
    }
  }, [])

  // Click listener
  useEffect(() => {
    document.getElementById(`slide-${props.slide.id}`).addEventListener('click', handleClicks, false);
    return () => {
      document.getElementById(`slide-${props.slide.id}`).removeEventListener('click', handleClicks, false);
    };
  }, []);

  const handleClicks = event => {
    const href = event?.target?.href;

    if (href && href !== '#') {
      event.stopPropagation();
      event.preventDefault();

      // gotoSlide
      if (href.indexOf('gotoSlide') > -1 || href.indexOf('gotoslide') > -1 || href.indexOf('goToSlide') > -1) {
        return false;
      }

      // reloadSlide
      else if (href.indexOf('reloadSlide') > -1 || href.indexOf('reloadslide') > -1) {
        return false;
      }

      // reloads all slides
      else if (href.indexOf('reloadAllSlides') > -1 || href.indexOf('reloadallslides') > -1) {
        return false;
      }

      // openDocument
      else if (href.indexOf('opendocument') > -1 || href.indexOf('openDocument') > -1) {
        return false;
      }

      // openExternal
      else if (href.indexOf('openExternal') > -1 || href.indexOf('openexternal') > -1) {
        let link = href;
        link = link.split('link=');
        link = link[1];
        window.open(link);
        return false;
      }

      // open viewer nav bar
      // * from i.e. fullscreen iframes that prevent viewer defaults
      else if (href.indexOf('openViewerMenu') > -1 || href.indexOf('openviewermenu') > -1) {
        return false;
      }

      else {
        window.open(href);
      }
    }
  };

  const isArchOrCircle = () => {
    if (json.reveal.slideLayout.id === 'one-column-arch-image' || json.reveal.slideLayout.id === 'one-column-half-circle-image' || json.reveal.slideLayout.id === 'campaign-info') {
      return true;
    } else {
      return false;
    }
  };

  const testIframe = (src) => {
    fetch(src)
      .then(res => {
        if (res.status === 404 || res.status === 403 || res.status === 500) {
          setIframeError(true);
        }
      });
  };

  const getAudioUrl = () => {
    let src = json.reveal.slideAudioData;

    if (typeof json.reveal.slideAudioData !== 'string') {
      src = json.reveal.slideAudioData.field_media_audio;

      if (props.live) {
        src = `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${json.reveal?.slideAudioData?.name}`;
      }
    }

    return src;
  };

  const bgStyling = {
    backgroundSize: json.reveal.slideInnerBackgroundSize,
    backgroundRepeat: json.reveal.slideInnerBackgroundRepeat,
    backgroundPosition: json.reveal.slideInnerBackgroundPosition,
    opacity: json.reveal.slideLayout.id !== 'one-column-arch-image' && json.reveal.slideLayout.id !== 'one-column-half-circle-image' && json.reveal.slideLayout.id !== 'campaign-info' ? json.reveal.slideInnerBackgroundOpacity : '',
    backgroundImage: isArchOrCircle() ? '' : json.reveal.slideInnerBackgroundImage !== '' ? `url("${backgroundImageURIGenerator(json.reveal.slideInnerBackgroundImage, props.live, props.slide, props.presentation_id)}")` : ''
  };

  const bgImage = json.reveal.slideBackgroundImage !== '' ? backgroundImageURIGenerator(json.reveal.slideBackgroundImage, props.live, props.slide, props.presentation_id) : '';

  let dataAutoAnimate = json.reveal.slideAutoAnimate ? {
    'data-auto-animate': ''
  } : {};

  const overlayRGB = json?.reveal?.slideOverlayColor && theme_json?.overlayRGB ? json?.reveal?.slideOverlayColor === 'dark' ? theme_json?.overlayRGB?.dark : theme_json?.overlayRGB?.light : '255,255,255';

  const isImgOverlay = json?.reveal?.slideLayout.id === 'image-with-vertical-overlay' || json?.reveal?.slideLayout.id === 'image-with-horizontal-overlay';

  const capacityString = () => {
    let classString = ''
    Object.keys(json.reveal.capacityTags).forEach((key) => {
      if (json.reveal.capacityTags[key]) {
        classString = `${classString} capacity-${key}`
      }
    });
    return classString;
  }

  return (
    <>
      <section
        className={'slide-wrap h-100'}
        // reveal params
        data-transition={json.reveal.slideTransition}
        data-background-transition={json.reveal.slideBackgroundTransition}
        data-background-color={json.reveal.slideBackgroundColor}
        data-background-image={bgImage}
        data-background-opacity={json.reveal.slideBackgroundOpacity}
        data-background-repeat={json.reveal.slideBackgroundRepeat}
        data-background-position={json.reveal.slideBackgroundPosition}
        data-background-size={json.reveal.slideBackgroundSize}
        data-theme={props.slide.theme.label}
        data-slide-layout={json.reveal.slideLayout.id}
        {...dataAutoAnimate}
        onClick={(event) => {
          // Open links in new tab
          if (event.target.href) {
            event.preventDefault();
            window.open(event.target.href, '_blank');
          }
        }}
      >
        <div id={`slide-${props.slide.id}`} className={`slide ${isSafari && 'safari'}`} style={json.reveal.slideLayout.id === 'custom' ? { overflow: 'hidden' } : {}}>
          <div className='slide-inner-background' style={{ backgroundColor: json.reveal.slideInnerBackgroundColor }}>
            <div className='slide-inner-background-content'
              style={bgStyling}>
              {json.reveal.slideLayout.id === 'section-title-arch-image' && (
                <SectionTitleArchImage />
              )}

              {json.reveal.slideLayout.id === 'one-column-arch-image' && (
                <OneColumnArchImage />
              )}

              {(json.reveal.slideLayout.id === 'one-column-half-circle-image' || json.reveal.slideLayout.id === 'campaign-info') && (
                <OneColumnHalfCircleImage />
              )}

              {json.reveal.slideLayout.id === 'image-with-vertical-gradient-overlay' && (
                <ImageWithVerticalGradientOverlay />
              )}
              {isArchOrCircle() &&
                <div className='d-flex justify-content-end w-100 h-100'>
                  <img
                    style={{
                      minWidth: 683,
                      maxWidth: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      marginRight: json.reveal.slideLayout.id === 'one-column-arch-image' ? '3%' : '',
                      opacity: json.reveal.slideInnerBackgroundOpacity
                    }}
                    alt=''
                    data-src={backgroundImageURIGenerator(json.reveal.slideInnerBackgroundImage, props.live, props.slide, props.presentation_id)} />
                </div>
              }
            </div>
          </div>
          <div
            className={`inner-container content-${contentCount} 
            ${json?.reveal?.slideLayout?.id === 'one-column-text-toggle' && toggleActive ? 'active' : ''}
            ${hasImage ? 'has-image' : ''} 
            ${hasSubtitle ? 'has-subtitle' : ''} 
            ${json.reveal.slideOverlayColor ? json.reveal.slideOverlayColor : ''} 
            ${json.reveal.slideLayoutScheme} 
            ${json.reveal.slideContentPosition} 
            ${isArchOrCircle() && json?.reveal?.archContentAlignment ? 'top' : ''} 
            ${json?.reveal?.slideLayout.id === 'data-table' ? `stub-column-${json?.reveal?.slideDataTableColumn ? json?.reveal?.slideDataTableColumn : 0}` : ''}
            ${isImgOverlay ? 'img-overlay' : ''}
            ${isImgOverlay ? `alpha-${Math.round(json?.reveal?.slideOverlayOpacity * 10)}` : ''}
            ${json?.reveal?.slideLayout.id === 'image-with-vertical-gradient-overlay' ? `content-${json?.reveal?.slideContentWidth}` : ''}
            ${json?.reveal?.slideLayout.id === 'at-a-glance' && capacityString()}
            `}
            style={json?.reveal?.slideLayout.id === 'image-with-vertical-overlay' ?
              {
                // backgroundColor: `rgba(${overlayRGB}, ${json?.reveal?.slideOverlayOpacity})`,
                // float: json?.reveal?.slideContentPosition,
                width: `${json?.reveal?.slideOverlayWidth}%`
              }
              :
              json?.reveal?.slideLayout.id === 'image-with-horizontal-overlay' ?
                {
                  // backgroundColor: `rgba(${overlayRGB}, ${json?.reveal?.slideOverlayOpacity})`,
                  position: 'absolute',
                  // top: json?.reveal?.slideContentPosition === 'left' ? 0 : 'auto',
                  // bottom: json?.reveal?.slideContentPosition === 'left' ? 'auto' : 0,
                }
                :
                {}
            }
          >
            {json?.reveal?.slideLayout?.id === 'advanced' && (
              <>
                {iframeError ? (
                  <div className='content-wrapper w-100 h-100 d-flex justify-content-center align-items-center' style={{ backgroundColor: '#fff' }}>
                    <h6 style={{ color: '#888' }}>Error loading slide. The slide may be missing or corrupt.</h6>
                  </div>
                ) : (
                  <Iframe id={props.slide.id} slide={props.slide} live={props.live} presentation_id={props.presentation_id} onTest={testIframe} />
                )}
              </>
            )}

            {json?.reveal?.slideLayout?.id === 'artotel-image-gallery' && props.slide.theme.label === 'artotel' &&
              <div className='content-wrapper' >
                <BespokeImageGallery presentation_id={props.presentation_id} live={props.live} title={title} images={images} />
              </div>
            }

            {json?.reveal?.slideLayout?.id === 'image-gallery' &&
              <div className='content-wrapper' >
                <ImageGallery presentation_id={props.presentation_id} live={props.live} title={title} images={images} variant={props.slide.theme.label === 'artotel' ? 'artotel' : ''} />
              </div>
            }

            {json?.reveal?.slideLayout?.id === 'fullscreen-image-gallery' && (
              <div className='content-wrapper' >
                <FullscreenImageGallery presentation_id={props.presentation_id} live={props.live} images={images} />
              </div>
            )}

            {json?.reveal?.slideLayout?.id === 'location-map' && (
              <>
                <div className='map-wrapper' dangerouslySetInnerHTML={{ __html: editorMapParser.parse(json).join('') }} />
                <div id="content-wrapper" className={`sidebar content-wrapper`}>
                  <div className='content' dangerouslySetInnerHTML={{ __html: editorParser.parse(json).join('') }} />
                  <IconButton className='sidebar-btn' onClick={() => {
                    document.getElementById('content-wrapper').classList.toggle('active');
                    const iframe = document.querySelector(`#map-${props.slide.id} iframe`);
                    const iframeWindow = iframe?.contentWindow;

                    iframeWindow?.document?.getElementById('map')?.classList.toggle('active');
                    iframeWindow.offsetMap();
                  }}>
                    <ChevronLeftIcon className='left' />
                    <ChevronRightIcon className='right' />
                  </IconButton>
                </div>
              </>
            )}
            {json?.reveal?.slideLayout?.id === 'country-map' && (
              <>
                <div className='map-wrapper'>
                  <div className='map'>
                    <div className='map-container'>
                      <CountryMapIframe
                        theme_json={theme_json}
                        themeDirectory={themeDirectory}
                        presentation_id={props.presentation_id}
                        live={props.live}
                        json={json}
                        slide={{ id: props.slide.id }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {json?.reveal?.slideLayout?.id === 'custom' &&

              <CustomLayoutCountryMap
                theme_json={theme_json}
                themeDirectory={themeDirectory}
                presentation_id={props.presentation_id}
                live={props.live}
                json={json}
                slide={{ id: props.slide.id }}
              />
            }
            {json?.reveal?.slideLayout?.id === 'one-column-text-toggle' && (
              <button
                className='toggle-btn'
                dangerouslySetInnerHTML={{ __html: AccordionArrow }}
                onClick={() => {
                  setToggleActive(!toggleActive);
                }}
              >
              </button>
            )}
            {
              json?.reveal?.slideLayout?.id !== 'advanced' &&
              json?.reveal?.slideLayout?.id !== 'image-gallery' &&
              json?.reveal?.slideLayout?.id !== 'artotel-image-gallery' &&
              json?.reveal?.slideLayout?.id !== 'fullscreen-image-gallery' &&
              json?.reveal?.slideLayout?.id !== 'location-map' &&
              json?.reveal?.slideLayout?.id !== 'country-map' && (
                <>
                  {isSlideEmpty ? (
                    <div className='content-wrapper w-100 h-100 d-flex justify-content-center align-items-center' style={{ backgroundColor: '#fff' }}>
                      <h6 style={{ color: '#888' }}>This slide has no content.</h6>
                    </div>
                  ) : (
                    <div className='content-wrapper' dangerouslySetInnerHTML={{ __html: editorParser.parse(json).join('') }} />
                  )}
                </>
              )
            }
            {json.reveal.slideAudioData &&
              <AudioPlayer
                isSlideAudio={true}
                audio={getAudioUrl()}
                audioObject={json.reveal.slideAudioData}
                audioTrigger={props.activeTrigger}
                autoPlay={json.reveal.slideAudioData.autoPlay}
                playOverride={props.playSlideAudio}
                audioPlaying={(value) => { props.onSlideAudioPlaying(value) }}
                showControls={props.showSlideAudioControls}
                hideSlideAudioControls={() => { props.hideSlideAudioControls() }}
                showPrompt={() => props.showPrompt()}
                promptVisible={props.promptVisible}
              />}
          </div>
          {json.reveal.slideLogo !== '' && (
            <div className={`logo-container ${json.reveal.slideLogoPosition}`}>
              {props.live ? (
                <img data-src={`${themeDirectory}/dist/images/logos/${json.reveal.slideLogo}`} alt={`slide-logo`} />
              ) : (
                <img data-src={`${themeDirectory}/dist/images/logos/${json.reveal.slideLogo}`} alt={`slide-logo`} />
              )}
            </div>
          )}
          {json.reveal.slideLockup && json.reveal.slideLockup !== '' && (
            <div className={`lockup-container ${json.reveal.slideLockupPosition}`}>
              {props.live ? (
                <img data-src={`${themeDirectory}/dist/images/lockups/${json.reveal.slideLockup}`} alt={`slide-lockup`} />
              ) : (
                <img data-src={`${themeDirectory}/dist/images/lockups/${json.reveal.slideLockup}`} alt={`slide-lockup`} />
              )}
            </div>
          )}
        </div>
        <aside className='notes'
          dangerouslySetInnerHTML={{ __html: props.slideNotes?.trim().replace(/\n/g, '<br/>') ?? '' }}
        >
        </aside>
      </section>
    </>
  );
};
