import React from "react";
import { useNavigate } from "react-router-dom";
import GridItem from "../../partials/gridItem";
import { serverUrl } from "../../config";

export default function PollItem(props) {
  const navigate = useNavigate();

  return (
    <GridItem
      xs={12}
      sm={6}
      md={3}
      itemClass="poll"
      bundle={props.item?.bundle}
      hasImg={true}
      id={props.item?.id}
      imgSrc={`${serverUrl}/${props.item.field_image}`}
      title={props.item.question}
      field_body={props.item?.summary ? props.item?.summary : props.item?.body}
      category_label={props.item?.category_labels}
      created={props.item.created}
      cardAction={true}
      // cardActionCallBack={() => {
      //   props.showPoll(props.item.id, props.item.question)
      // }}
      cardActionCallBack={() => navigate(`/polls/${props.item?.id}`)}
      contentType={props.contentType}
    >
    </GridItem>
  );
}
