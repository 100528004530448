import React from "react";

import { serverUrl } from "../../config";

import GridItem from "../../partials/gridItem";

export default function GalleryItem(props) {

  return (
    <GridItem
      xs={12} sm={6} md={6}
      itemClass="gallery"
      bundle={props.item?.bundle}
      linkTo={true}
      route="galleries"
      nid={props.item?.nid}
      title={props.item?.title}
      field_body={props.item?.summary ? props.item?.summary : props.item?.body}
      category_label={props.item?.category_labels}
      created={props.item?.created}
      hasImg={true}
      newsVariant={"featured"}
      imgSrc={`${serverUrl}${props.item.field_featured_image}`}/>
  )
}