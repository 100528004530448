import React, { useEffect, useState } from 'react';

// Modules
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

// App
import { serverUrl, counterEnabled } from "../../config";
import { getOnlineNode } from '../../core/getNode';
import CompanyappLoader from '../../partials/companyappLoader';
import NoData from '../../partials/noData';
import Presentation from './partials/presentation/edit/presentation';
import PresentationFilters from './partials/filters';
import Pagination from '../../partials/pagination';
import { getFavoritesByContentType, favoritesContentType } from '../../utils/helpers';
import { patchNode } from "../../core/postNode";

// UI components
import { Row, Col } from 'react-bootstrap';
import { Box, Button, Alert } from '@mui/material/';
import { ArrowBack } from '@mui/icons-material';

const pageSize = 24;
const contentType = favoritesContentType('presentation');

export default function PresentationsCreator() {
  // redux
  const user = useSelector((state) => state).authReducer.user;
  const permissions = useSelector((state) => state).permissionsReducer.permissions;
  const filterReducer = useSelector((state) => state).filtersReducer;
  const counter = useSelector((state) => state).counterReducer;

  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);
  const [isSearching, setSearching] = useState(false);
  const [isError, setError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [totalItems, setTotalItems] = useState(0);

  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [themes, setThemes] = useState([]);
  const [sitas, setSitas] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const [presentations, setPresentations] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const favorites = useSelector((state) => state).favoritesReducer;
  const favStrings = getFavoritesByContentType(favorites, contentType, true);

  useEffect(() => {
    if (counterEnabled) {
      updateRemoteCounter();
    }
  }, []);

  // initial render
  useEffect(() => {
    getCategories();
  }, []);

  // redux listener
  // fetch content when filters are updated
  // also runs on initial render
  useEffect(() => {
    // navigate(`?page=${filterReducer.currentPage}`);
    getContent();

    /**
     * @todo Fetch pageNumber from url
     * something like below
     */

    // const params = new URLSearchParams(location.search);
    // const page = params.get('page') ? parseInt(params.get('page')) : 1;

    // if (page !== 1) {
    //   filterReducer.currentPage = page;
    //   dispatch({ type: 'UPDATE_FILTERS', payload: filterReducer });
    // } else {
    //   navigate(`?page=${filterReducer.currentPage}`);
    //   getContent();
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterReducer, navigate]);

  const getCategories = () => {
    getOnlineNode('api/categories?vid=presentation_categories,language,themes,sita&used=1')
      .then((response) => {
        let newCategories = [{ value: 0, label: 'All Categories', has_content: true, has_view_permission: true, }];
        let newLanguages = [{ value: 0, label: 'All Languages' }];
        let newThemes = [{ value: 0, label: 'All Themes' }];
        let newSitas = [{ value: '0', tid: '0', label: 'NONE', title: 'NONE' }];

        response.data.forEach(item => {
          if (item.has_view_permission === true) {
            if (item.vid === 'presentation_categories') {
              newCategories.push({
                ...item,
                value: item.tid,
                label: item.title,
              });
            }

            if (item.vid === 'language') {
              newLanguages.push({
                ...item,
                value: item.tid,
                label: item.title,
              });
            }

            if (item.vid === 'themes') {
              newThemes.push({
                ...item,
                value: item.tid,
                label: item.label ? item.label : item.title
              });
            }

            if (item.vid === 'sita') {
              newSitas.push({
                ...item,
                value: item.tid,
                label: item.title,
              });
            }
          }
        });

        setThemes(newThemes);
        setLanguages(newLanguages);
        setCategories(newCategories);
        setSitas(newSitas);
      })
      .catch((error) => {
        // failed to get taxonomies
        console.log('Failed to get taxonomies: ', error);
      });
  };

  useEffect(() => {
    if(activeIndex===1 && favStrings===""){
      setLoading(false);
      setSearching(false);
      setPresentations([]);
      setTotalItems(0);
    }
    else{
      getContent();
    }
  }, [activeIndex]);

  useEffect(() => {
    if (activeIndex === 1) {
      if (favStrings !== "") {
        getContent();
      }
      else {
        setPresentations([]);
        setSearching(false);
      }
    }
  },[favStrings])

  const getContent = () => {
    setSearching(true);

    let path = `api/user_presentations?items_per_page=${pageSize}&page=${filterReducer.currentPage ? filterReducer.currentPage - 1 : 0}`;

    if (filterReducer.category && filterReducer.category !== 0) {
      path = path + '&category=' + filterReducer.category;
    }

    if (filterReducer.title && filterReducer.title !== '') {
      path = path + '&id_or_title=' + filterReducer.title;
    }

    if (filterReducer.author && filterReducer.author !== '') {
      if (typeof filterReducer.author === 'string') {
        path = path + '&author_name=' + filterReducer.author;
      } else {
        if (filterReducer.author.value) {
          path = path + '&author_name=' + filterReducer.author.value;
        }
      }
    }

    if (filterReducer.language && filterReducer.language !== 0) {
      path = path + '&language=' + filterReducer.language;
    }

    if (filterReducer.status && filterReducer.status !== 0 && filterReducer.status.length > 0) {
      path = path + '&presentation_status=' + filterReducer.status;
    }

    if (filterReducer.library && filterReducer.library !== 0) {
      path = path + '&library=' + filterReducer.library;
    }

    if (filterReducer.theme && filterReducer.theme !== 0) {
      path = path + '&theme=' + filterReducer.theme;
    }

    if (filterReducer.idSita && filterReducer.idSita !== '') {
      path = path + '&id_or_sita=' + filterReducer.idSita;
    }

    if (presentations.length > 0) {
      const lastElement = presentations[presentations.length - 1];

      if (lastElement && lastElement.id) {
        path += `&lastId=${lastElement.id}`;
      }
    }

    if (activeIndex === 1 && favStrings !== "") {
      path += `&id=${favStrings}`
    }

    getOnlineNode(path)
      .then((response) => {
        setLoading(false);
        setSearching(false);
        setPresentations(response.data.rows);
        setTotalItems(response.data.pager.total_items);
      })
      .catch((error) => {
        setError(true);

        if (error?.response?.status) {
          setErrorStatus(error.response.status);
        }

        if (error?.response?.statusText) {
          setErrorMessage(error.response.statusText);
        } else {
          setErrorMessage('Failed to fetch presentations. Unknown error!');
        }
      });
  };

  const updateRemoteCounter = () => {
    const updateData = {
      contentType: 'presentation',
      acknowledgedCount: counter?.contentCounts?.presentation || 0,
    };

    patchNode(`${serverUrl}/api/v2/counter`, updateData, user.csrf_token)
      .then((response) => {
        if (response.data) {
          dispatch({ type: "UPDATE_COUNTER", payload: response.data });
        }
      })
      .catch((error) => {
        // console.log('error: ', error);
      });
  };

  return (
    <>
      {permissions.access_presentation_creator_mode !== true ? (
        <Row className='mt-5 justify-content-center align-self-center'>
          <Col>
            <Box className='d-flex h-100 justify-content-center align-items-center align-content-center' sx={{ flexDirection: 'column' }}>
              <Alert severity='error'>You don't have permission to access this screen.</Alert>
              <Button
                className='mt-3'
                variant='outlined'
                startIcon={<ArrowBack />}
                onClick={() => {
                  this.props.navigate('/presentations');
                }}
              >
                Back
              </Button>
            </Box>
          </Col>
        </Row>
      ) : (
        <>
          {isLoading ? (
            <div className='circular-container fs'>
              <CompanyappLoader />
            </div>
          ) : (
            <>
              {isError ? (
                <Row className='h-100 justify-content-center align-self-center'>
                  <Col>
                    <Box className='d-flex h-100 justify-content-center align-items-center align-content-center' sx={{ flexDirection: 'column' }}>
                      <Alert variant='filled' severity='error'>
                        {errorStatus ? `${errorStatus} - ` : ''}{errorMessage}</Alert>
                    </Box>
                  </Col>
                </Row>
              ) : (
                <main className='presentations creator screen'>
                  <PresentationFilters
                    advanced={true}
                    pageTitle='Presentations'
                    toggleSwitch={true}
                    switchLink={'/presentations'}
                    switchStatus={true}
                    categories={categories}
                    languages={languages}
                    themes={themes}
                    createPresentation={true}
                    user={user}
                    redux={true}
                    onUpdate={() => {
                      filterReducer.currentPage = 1;
                      dispatch({ type: 'UPDATE_FILTERS', payload: filterReducer });
                    }}
                    itemCounter={false}
                    totalItems={totalItems}
                    pageSize={pageSize}
                    currentPage={filterReducer.currentPage}
                    data={presentations}
                    activeTabIndex={activeIndex}
                    getFavourites={() => {
                      setActiveIndex(activeIndex === 0 ? 1 : 0);
                      setSearching(true);
                    }}
                  />
                  {isSearching ? (
                    <div className='circular-container fs t'>
                      {/* <CircularProgress size={20} /> */}
                      <CompanyappLoader />
                    </div>
                  ) : (
                    <>
                      {presentations.length > 0 ? (
                        <>
                          <Row className='presentation-items-container'>
                            {presentations.map((item, index) => (
                              <Presentation
                                key={`presentation-${item.id}-index-${index}`}
                                item={item}
                                categories={categories}
                                languages={languages}
                                themes={themes}
                                sitas={sitas}
                                user={user}
                                getContent={getContent}
                                contentType={contentType}
                              />
                            ))}
                          </Row>
                          <Pagination
                            owner="admin"
                            containerClasses={`creator-pagination d-flex justify-content-center align-items-center mt-4`}
                            previousDisabled={filterReducer.currentPage === 1 ? true : false}
                            nextDisabled={presentations.length < pageSize ? true : false}
                            currentPage={filterReducer.currentPage}
                            onPrevious={() => {
                              filterReducer.currentPage = filterReducer.currentPage <= 1 ? 1 : filterReducer.currentPage - 1;
                              dispatch({ type: 'UPDATE_FILTERS', payload: filterReducer });
                            }}
                            onNext={() => {
                              const current = filterReducer.currentPage + 1;
                              filterReducer.currentPage = current;
                              dispatch({ type: 'UPDATE_FILTERS', payload: filterReducer });
                            }}
                          />
                        </>
                      ) : (
                        <NoData activeIndex={0} />
                      )}
                    </>
                  )}
                </main>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}