import React from "react";

// Modules
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// App
import { apiEndpoints, serverUrl, favoritesEnabled, counterEnabled } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import Pagination from "../../partials/pagination";
import PageHeader from "../../partials/pageHeader";
import { alertMessages } from "../../partials/alertMessages";
import Error from "../../partials/error";
import { getFavoritesByContentType, favoritesContentType } from "../../utils/helpers";
import NoData from "../../partials/noData";
import FormItem from "./formItem";
import ScreenCategories from "../../partials/screenCategories";
import ClientTheme from "../../core/clientTheme";
import { patchNode } from "../../core/postNode";

// UI components
import { Row } from "react-bootstrap";
import CompanyappLoader from "../../partials/companyappLoader";

const contentType = favoritesContentType('webform');

export default function Forms(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [index, setIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [token, setToken] = useState("");
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(0);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [activeForm, setActiveForm] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const user = useSelector((state) => state).authReducer.user;
  const favorites = useSelector((state) => state).favoritesReducer;
  const counter = useSelector((state) => state).counterReducer;
  const favStrings = getFavoritesByContentType(favorites, contentType, true);
  const clientTheme = { ...ClientTheme() };

  // hooks
  const dispatch = useDispatch();

  useEffect(() => {
    if (counterEnabled) {
      updateRemoteCounter();
    }
  }, []);

  useEffect(() => {
    if (categories.length <= 1) {
      getCategories();
    }
    if ((activeIndex === 1 && favStrings !== "") || activeIndex === 0) {
      getContent(1);
    } else {
      setData([]);
      setIsLoading(false);
    }
  }, [selectedCategoryItem, activeIndex]);

  useEffect(() => {
    if (activeIndex === 1) {
      if (favStrings !== "") {
        getContent(1);
      } else {
        setData([]);
        setIsLoading(false);
      }
    }
  }, [favStrings]);

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const _setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsRefreshing(false);

    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (_currentPage) => {
    let path = `${
      apiEndpoints.forms
    }?status=1&promote=1&items_per_page=${pageSize}&page=${currentPage - 1}`;

    const tid = selectedCategory;
    if (activeIndex === 1) {
      path = `${
        apiEndpoints.forms
      }/all/${favStrings}?status=1&promote=1&items_per_page=${pageSize}&page=${
        currentPage - 1
      }`;

      if (tid !== 0) {
        path = `${
          apiEndpoints.forms
        }/${tid}/${favStrings}?status=1&promote=1&items_per_page=${pageSize}&page=${
          _currentPage - 1
        }`;
      }
    } else {
      if (tid !== 0) {
        path = `${
          apiEndpoints.forms
        }/${tid}/all?status=1&promote=1&items_per_page=${pageSize}&page=${
          _currentPage - 1
        }`;
      }
    }

    getOnlineNode(path, user.access_token)
      .then((response) => {
        // dataHandler(response.data);
        setData(response.data.rows);
        setIsLoading(false);
      })
      .catch((_error) => {
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          _setError(true, _error.response.status, _error.response.statusText);
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getCategories
   * @description Retrives the category data from an API / Fallback to local realm object if there is no connection
   */
  const getCategories = () => {
    getOnlineNode(
      `${apiEndpoints.categories}webform_categories`,
      user.access_token
    )
      .then((response) => {
        renderCategories(response.data);
      })
      .catch((_error) => {
        if (_error.response) {
          _setError(true, _error.response.status, _error.response.statusText);
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  const onPaginate = (_index) => {
    setIsLoading(true);
    setCurrentPage(_index);
    getContent(_index);
  };

  /**
   * @function renderCategories
   * @description Updates the state with the retrived categories
   * @param {object} _categories
   */
  const renderCategories = (_data) => {
    let categories = [
      {
        value: 0,
        label: "All Forms",
      },
    ];

    _data.forEach((item) => {
      categories.push({
        value: item.tid[0].value,
        label: item.name[0].value,
      });
    });

    setCategories(categories);
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  const handleCategorySelection = (value) => {
    setIsLoading(true);

    setIsError(false);
    setCurrentPage(1);
    setSelectedCategory(parseInt(value, 10));
    setSelectedCategoryItem(value);
    setCategoryOpen(false);
  };

  // Pagination component
  const renderPagination = () => {
    if (totalItems <= pageSize) {
      return null;
    } else {
      return (
        <Pagination
          owner="client"
          containerClasses={`creator-pagination d-flex justify-content-center align-items-center`}
          previousDisabled={currentPage === 1 ? true : false}
          nextDisabled={data.length < pageSize ? true : false}
          currentPage={currentPage}
          onPrevious={() => {
            onPaginate(currentPage - 1);
          }}
          onNext={() => {
            onPaginate(currentPage + 1);
          }}
        />
      );
    }
  };

  const getCategoryName = (id) => {
    let categoryName = categories.find((e) => e.value === id);
    return categoryName ? categoryName.label : "";
  };

  // Data component
  const dataComponent = () => {
    if (typeof data !== "undefined" && data.length > 0) {
      return (
        <Row>
          {data.map((item, index) => {
            return (
              <React.Fragment key={`form-${index}`}>
                <FormItem
                  item={item}
                  showForm={(id, question) => handleActiveForm(id, question)}
                  contentType={contentType}
                />
              </React.Fragment>
            );
          })}
        </Row>
      );
    } else {
      return <NoData activeIndex={activeIndex} />;
    }
  };

  const handleActiveForm = (id, question) => {
    setActiveForm({
      id: id,
      question: question,
    });
    setDialogOpen(true);
  };

  const clearActiveForm = () => {
    setActiveForm("");
    setDialogOpen(false);
  };

  // Updates content counter
  const updateRemoteCounter = () => {
    const updateData = {
      contentType: 'webform',
      acknowledgedCount: counter?.contentCounts?.webform || 0,
    };

    patchNode(`${serverUrl}/api/v2/counter`, updateData, user.csrf_token)
      .then((response) => {
        if (response.data) {
          dispatch({ type: "UPDATE_COUNTER", payload: response.data });
        }
      })
      .catch((error) => {
        // console.log('error: ', error);
      });
  };

  return (
    <>
      {isError ? (
        <Error status={errorStatus} message={errorMessage} />
      ) : (
        <main className="forms  screen">
          <PageHeader
            pageName="Surveys & Forms"
            filters={true}
            owner="client"
            pageNameColor="clientHeadingScreens"
            svgLogo={clientTheme.pageLogos.svg}
            pageLogo={clientTheme.pageLogos.forms}
            enableFavorites={favoritesEnabled}
            categorySelector={
              <ScreenCategories
                title="Forms"
                categoryName="forms"
                selectedCategoryItem={selectedCategoryItem}
                categoryOpen={categoryOpen}
                setCategoryOpen={(value) => setCategoryOpen(value)}
                handleCategorySelection={(value) =>
                  handleCategorySelection(value)
                }
                categories={categories}
              />
            }
            getContent={() => {
              setActiveIndex(0);
              setIsLoading(true);
              getContent(1);
            }}
            getFavourites={() => {
              setActiveIndex(activeIndex === 0 ? 1 : 0);
              setIsLoading(true);
            }}
            activeTabIndex={activeIndex}
          />
          {isLoading ? (
            <CompanyappLoader />
          ) : (
            <>
              {dataComponent()}
              {renderPagination()}
            </>
          )}
        </main>
      )}
      {/* <Dialog
        className="form-dialog"
        open={dialogOpen}
        onClose={() => clearActiveForm()}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            height: 1,
            borderRadius: "25px"
          }
        }}
      >

        <Box>
          <Container className='px-5 pt-4' fluid>
            <Row className='d-flex align-items-center justify-content-between'>
              <Col xs={9}>
                <Typography variant='h3' owner='client' color='clientText.dark'>{activeForm.question}</Typography>
              </Col>
              <Col className="d-flex justify-content-end"xs={3}>
                <IconButton
                  className="close-dialog-btn"
                  onClick={() => clearActiveForm()}
                >
                  <CloseIcon />
                </IconButton>
              </Col>
            </Row>
          </Container>
        </Box>
        <DialogContent className="form-dialog-content ps-modal-content p-0 mt-3" sx={{ minHeight: '500px', position: 'relative' }}>
          <iframe className='w-100 h-100  px-5 pt-0' src={`${serverUrl}/node/${activeForm.id}?app=true`} frameborder="0" style={{ position: 'absolute' }}></iframe>
        </DialogContent>
      </Dialog> */}

      {/* <IframeDialog
        open={dialogOpen}
        onClose={() => clearActiveForm()}
        title={activeForm.question}
        src={`${serverUrl}/node/${activeForm.id}?app=true`}
      /> */}
    </>
  );
}
