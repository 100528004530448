import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Modules

// App
import Error from "../../../partials/error";
import { getOnlineNode } from "../../../core/getNode";
import { apiEndpoints } from "../../../config";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LatestItem from "./latestItem";
import FeaturedItem from "./featuredItem";
import { Typography } from "@mui/material";
import LoadingSpinner from "../../../partials/loadingSpinner";

const pageSize = 24;

export default function Home(props) {
  const [isLoading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState(null);
  const [feed, setFeed] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [isFetchingMore, setFetchingMore] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [welcomeText, setWelcomeText] = useState("");

  const user = useSelector((state) => state).authReducer.user;

  useEffect(() => {
    getData();
    getUser();
  }, []);

  useEffect(() => {
    getFeedData();
  }, [currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 100 &&
        !isFetchingMore
      ) {
        loadMore();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetchingMore]);

  const loadMore = () => {
    if (!isFetchingMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const getUser = () => {
    getOnlineNode("custom_user/" + user.current_user.uid)
      .then((response) => {
        setUserDetails(response.data.rows[0]);
      })
      .catch((_error) => {
        console.log("error", _error);
      });
  };

  const getData = () => {
    getOnlineNode(apiEndpoints.home)
      .then((response) => {
        setData(response.data);
        setWelcomeText(response.data.welcome_text);
        setLoading(false);
      })
      .catch((error) => {});
  };

  const getFeedData = () => {
    // prevent overload and overfetch
    if (isFetchingMore || (totalPages && currentPage >= totalPages)) return;

    setFetchingMore(currentPage > 0);

    getOnlineNode(
      `${apiEndpoints.home2}?items_per_page=${pageSize}&page=${currentPage}`
    )
      .then((response) => {
        setFeed((prevData) => [...prevData, ...response.data.rows]);
        setWelcomeText(response.data.welcome_text);
        setTotalPages(response.data.pager.total_pages);
      })
      .finally(() => {
        setLoading(false);
        setFetchingMore(false);
      });
  };

  const renderGreeting = () => {
    if (user) {
      return (
        <>
          <Row className="gx-0 mb-3">
            <Col xs={"auto"} className="me-2">
              <Typography
                variant="h1"
                owner={"client"}
                weight="medium"
                color="clientText.dark"
              >
                Hello,
              </Typography>
            </Col>
            <Col xs={"auto"}>
              <Typography
                variant="h1"
                owner={"client"}
                weight="medium"
                color="clientPrimary.main"
              >
                {userDetails.field_first_name
                  ? userDetails.field_first_name
                  : userDetails.name}
              </Typography>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Typography
                variant="body1"
                owner="client"
                weight="light"
                color="clientText.main"
                lh="wide"
              >
                {welcomeText}
              </Typography>
            </Col>
          </Row>
        </>
      );
    }
  };

  const renderLatest = () => {
    if (feed && feed.length > 0) {
      return (
        <>
          <Row className="mb-3 gx-0">
            <Typography
              className="latest-header section-header pb-2"
              variant="h1"
              owner={"client"}
              weight="medium"
              color="clientText.dark"
            >
              Latest
            </Typography>
          </Row>
          <Row>
            {feed.map((item) => (
                <LatestItem item={item} />
            ))}
          </Row>
        </>
      );
    }
  };

  const renderFeatured = () => {
    if (data && data.featured.items.length > 0) {
      return (
        <Row className="home-featured-container d-none d-xl-block">
          <Col xs={12}>
            <Row className="gx-0">
              <Typography
                className="featured-header section-header pb-2"
                variant="h5"
                owner={"client"}
                weight="medium"
                color="clientText.dark"
              >
                Featured
              </Typography>
            </Row>
            <Row className="gx-0">
              {data.featured.items.map((item) => (
                <FeaturedItem item={item} />
              ))}
            </Row>
          </Col>
        </Row>
      );
    }
  };

  if (isError) {
    return <Error status={errorStatus} message={errorMessage} />;
  } else {
    return (
      <main className="home screen">
        <Row className="mt-4 justify-content-center">
          <Col
            className="feed-column py-5 px-3 px-sm-4 position-relative"
            xs={12}
            sm={10}
            md={8}
            lg={7}
            xl={8}
            xxl={11}
          >
            {renderGreeting()}
            {feed && feed.length > 0 && renderLatest()}
            {(isFetchingMore || isLoading) && (
              <Row>
                <Col xs={12}>
                  <LoadingSpinner />
                </Col>
              </Row>
            )}
            {/* {renderFeatured()} */}
          </Col>
          {/* {renderTwitterTimeline()} */}
        </Row>
      </main>
    );
  }
}
