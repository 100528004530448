import React from "react";

import { useState } from 'react';
import { Row, Col } from "react-bootstrap";
import GridItem from "../../partials/gridItem";
import { serverUrl } from "../../config";


export default function CompanyDirectoryItem(props) {

  function cleanString(inputString) {
    const cleanedString = inputString.replace(/&\s/g, '');

    return cleanedString;
  }


  return (
    <>
      <GridItem
        xs={12}
        sm={6}
        itemClass="company-directory"
        bundle={props.item?.bundle}
        linkTo={true}
        companyDirectory={true}
        route="company_directory"
        title={props.item.title}
        altStringAvatar={cleanString(props.item.title)}
        field_body={props.item.summary || props.item.body}
        user_picture={`${serverUrl}${props.item.field_featured_image}`}
        textContainerCol={9}
        created={props.item.changed}
        nid={props.item.nid}
        disableFavorites={true}
        disableFooterMargin={true}
        disableChip={true}
        hasAvatar={true}
        contentType={props.contentType}
      />
    </>
  )
}