// Dependencies
import React, { useState, useRef, useEffect } from "react";

// Modules
import { useSelector } from "react-redux";

// App
import { serverUrl, apiEndpoints } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import PostImage from "../../partials/postImage";
import PostBody from "../../partials/postBody";
import SinglesHeader from "../../partials/singlesHeader";
import PageHeader from "../../partials/pageHeader";

// UI components
import { CircularProgress, Hidden } from "@mui/material";
import SkeletonSingleScreen from "../../partials/skeleton-screens/skeletonSingleScreen";

export default function Contribute(props) {
  const [iframeLoading, setIframeLoading] = useState(true);
  const iframeRef = useRef(null);
  const user = useSelector((state) => state).authReducer.user;

  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);

  useEffect(() => {
    getOnlineNode(apiEndpoints.contribute, user.access_token)
      .then((response) => {
        console.log('response', response);
        if (response.data) {
          setData(response.data);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  if (isLoading || !data) {
    console.log('data', data);
    return <SkeletonSingleScreen />;
  } else {
    return (
      <main className="contribute screen">
        <PageHeader
          pageName="Contribute"
          owner="client"
          pageNameColor="clientText.dark"
        />
        <div className="ratio ratio-4x3" style={{ height: "100vh" }}>
          {data?.webform && (
            <>
              {iframeLoading && (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                  <CircularProgress />
                </div>
              )}
              <iframe
                className={`${iframeLoading && "invisible"}`}
                src={`${serverUrl}/node/${data.nid[0].value}?app=true`}
                ref={iframeRef}
                style={{ width: "100%", height: "1080px", border: "none" }}
                title="Iframe Content"
                onLoad={(iframe) => {
                  setIframeLoading(false);
                }}
              />
            </>
          )}
        </div>
      </main>
    );
  }
}
