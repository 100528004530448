import {createStore, applyMiddleware, compose} from 'redux';
import ReduxThunk from 'redux-thunk';

import { authReducer } from './authReducer';
import { searchReducer } from './searchReducer';
import { navigationReducer } from './navReducer';
import { locationReducer } from './locationReducer';
import { headerReducer } from './headerReducer';
import { permissionsReducer } from './permissions';
import { filtersReducer } from './filtersReducer';
import { themesReducer } from './themesReducer';
import { noticeReducer } from './noticeReducer';
import { contentReducer } from './contentReducer';
import { favoritesReducer } from './favoritesReducer';
import { tokenReducer } from './tokenReducer';
import { iceportalReducer } from './iceportalReducer';
import { counterReducer } from './counterReducer';

import {combineReducers} from 'redux';

const middlewares = [ReduxThunk];

const appReducer = combineReducers({
  authReducer: authReducer,
  searchReducer: searchReducer,
  navigationReducer: navigationReducer,
  locationReducer: locationReducer,
  headerReducer: headerReducer,
  permissionsReducer: permissionsReducer,
  filtersReducer: filtersReducer,
  themesReducer: themesReducer,
  noticeReducer: noticeReducer,
  contentReducer: contentReducer,
  favoritesReducer: favoritesReducer,
  tokenReducer: tokenReducer,
  iceportalReducer: iceportalReducer,
  counterReducer: counterReducer,
});

const store = createStore(
  appReducer,
  {}, // default state of the application
  compose(applyMiddleware(...middlewares)),
);

export default store;