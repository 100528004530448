import React from "react";

import { useState } from "react";
import { Link } from "react-router-dom";

import PostExcerpt from "./postExcerpt";
import { decodeHtmlEntities, formatTime } from "../utils/helpers";
import UserImage from "./userImage";
import { useWindowSize } from "../partials/windowSize"

import { Typography, Chip, Box, Container, Button } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import OpenNewIcon from '@mui/icons-material/OpenInNewOutlined';
import FavouriteBtn from "./favouriteBtn";
import ClientTheme from "../core/clientTheme";
import { favoritesEnabled } from "../config";
import { SvgIcon } from "@mui/material";
import TagIcon from '@mui/icons-material/LocalOfferOutlined';
import NoImage from "./noImage";
import ThumbsUpIcon from '@mui/icons-material/ThumbUpOutlined';
import CommentIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { MessageSquare, ThumbsUp } from "react-feather";

export default function GridItem(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [imageError, setImageError] = useState(false);
  const itemClass = props.itemClass;

  const dateCreated = new Date(props.created * 1000);
  const day = dateCreated.toLocaleString("en-GB", { day: "2-digit" });
  const month = dateCreated.toLocaleString("en-GB", { month: "short" });
  const year = dateCreated.toLocaleString("en-GB", { year: "2-digit" });
  const timestamp = `${day} ${month.substring(0, 3)} ${year}`;

  const clientTheme = { ...ClientTheme() };
  const { width } = useWindowSize();

  const processEventDate = () => {
    let start = props.eventStartDate.date;
    let end = props.eventEndDate.date;

    if (start.day === end.day && start.month === end.month) {
      return (
        <Typography
          owner="client"
          variant="h2"
        >
          {end.day}
        </Typography>
      )
    } else if (start.day != end.day && start.month === end.month) {
      return (
        <Typography
          owner="client"
          variant="h5"
        >
          {`${start.day} - ${end.day}`}
        </Typography>
      );
    } else {
      return (
        <Typography
          owner="client"
          variant={`${width < 576 ? "body2" : "subtitle1"}`}
          weight="semiBold"
          lh={`${width < 576 ? "medium" : "base"}`}
        >
          {`${start.day} ${start.month.substring(0, 3)}`} <br />
          {`${end.day} ${end.month.substring(0, 3)}`}
        </Typography>
      );
    }
  }

  const processEventDay = () => {
    let start = props.eventStartDate.date;
    let end = props.eventEndDate.date;
    let startDay = props.eventStartDate.weekday;
    let endDay = props.eventEndDate.weekday;

    if (start.day === end.day && start.month === end.month) {
      return endDay
    } else {
      return `${startDay.substring(0, 2)} - ${endDay.substring(0, 2)}`
    }
  }

  const renderEventDate = () => {
    return (
      <Col xs={6}>
        <Container className="grid-item-event-main"
          variant="eventContainer"
        >
          <Box
            className="grid-item-event-day p-1 d-flex justify-content-center align-items-center"
          >
            <Typography
              owner="client"
              variant="overline"
              weight="semiBold"
              lh="regular"
            >
              {processEventDay()}
            </Typography>
          </Box>
          <Box
            className="grid-item-event-date"
          >
            {processEventDate()}
          </Box>
        </Container>
      </Col>
    );
  }

  const renderImageContainer = (imgColWidth, imgRatio) => {
    let ratio = "";
    switch (imgRatio) {
      case "wide":
        ratio = "ratio ratio-16x9";
        break;
      case "square":
        ratio = "ratio ratio-1x1";
        break;
      case "free":
      default:
        ratio = "";
        break;
    }
    return (
      <Col xs={imgColWidth || 12} className="grid-item-card-media-container">
        <div className={`grid-item-card-img-container ${ratio}`}>
          <img className="grid-item-card-img" src={imageError ? NoImage() : props.imgSrc} alt={`Image for item ${props.title}`} onError={() => setImageError(true)} />
        </div>
      </Col>
    )
  }

  const renderOverline = () => {
    return (
      <Row className="overline-container d-flex justify-content-between align-items-center">
        {props.overlineCategory &&
          <Col>
            <Row>
              {props.overlineCategoryLogo &&
                <Col xs={"auto"} className="d-flex flex-row align-items-center pe-0">
                  <Typography
                    className="grid-item-card-overline-category-logo"
                    variant={props.overlineCategoryVariant || 'caption'}
                    owner="client"
                    color="clientPrimary.main"
                    family="primary"
                    weight={props.overlineCategoryWeight || "light"}
                  >
                    {props.overlineCategoryLogo}
                  </Typography>
                </Col>
              }
              <Col xs={"auto"} className={`d-flex flex-row align-items-center ${!!props.overlineCategoryLogo && "ps-2"}`}>
                <Typography
                  className="grid-item-card-overline-category"
                  variant={props.overlineCategoryVariant || 'caption'}
                  owner="client"
                  color="clientPrimary.main"
                  family="primary"
                  weight={props.overlineCategoryWeight || "light"}
                >
                  {props?.category_label ? props?.category_label : "Uncategorized"}
                </Typography>
              </Col>
            </Row>
          </Col>
        }
        {props.overlineStats &&
          <Col xs={"auto"} className="d-flex flex-row align-items-center">
            <Row>
              <Col className="pe-0">
                <Typography
                  className="grid-item-card-overline-reaction d-flex align-items-center"
                  variant={props.overlineCategoryVariant || 'caption'}
                  owner="client"
                  color="clientPrimary.main"
                  family="primary"
                  weight={props.overlineCategoryWeight || "light"}
                >
                  {props.reactionCount}
                  <ThumbsUpIcon
                    className="ms-1"
                    sx={{ fontSize: '1rem', transform: "translateY(-1px)" }}
                  />
                </Typography>
              </Col>
              <Col>
                <Typography
                  className="grid-item-card-overline-comment d-flex align-items-center"
                  variant={props.overlineCategoryVariant || 'caption'}
                  owner="client"
                  color="clientPrimary.main"
                  family="primary"
                  weight={props.overlineCategoryWeight || "light"}
                >
                  {props.commentCount}
                  <CommentIcon
                    className="ms-1"
                    sx={{ fontSize: '1rem', transform: "translateY(1px)" }}
                  />
                </Typography>
              </Col>
            </Row>
          </Col>
        }
      </Row>
    );
  }

  const renderActionArea = () => {
    return (
      <Box
        className="grid-item-card-action-area"
        onClick={() => {
          if (props.cardActionCallBack) {
            props.cardActionCallBack(props.args)
          }
        }}
      >
        <Box
          className="grid-item-content"
        >
          <Row className="image-container">
            {props.hasImg && (
              props.event ?
                <Col className="event-date-container">
                  <Row className="gx-0 event-date-container-row">
                    {renderEventDate()}
                    {renderImageContainer(props.imageCol || 6, props.imageRatio || 'square')}
                  </Row>
                </Col>
                :
                renderImageContainer(props.imageCol || 12, props.imageRatio || 'wide')
            )
            }
            {props.hasAvatar && (
              <Col xs={props.textContainerCol ? 12 - props.textContainerCol : 3} className="pe-0">
                <UserImage
                  className="ratio ratio-1x1"
                  owner="client"
                  sub_variant="user-image"
                  name={props.altStringAvatar ? props.altStringAvatar : props.title}
                  image={props.user_picture}
                />
              </Col>
            )}
            <Col
              className="text-container"
              xs={props.textContainerCol === "" ? "" : props.textContainerCol || 12}
            >
              {(props.overlineCategory || props.overlineStats) && renderOverline()}
              <Typography
                className="grid-item-card-title"
                variant={props.titleVariant ? props.titleVariant : (props.newsVariant === "featured" ? "h4" : (!props.event) && (width < 576) && (width > 375) ? "h5" : "subtitle1")}
                owner="client"
                color={isHovered ? props.newsVariant === "featured" ? "clientText.contrastText" : "clientPrimary.main" : props.newsVariant === "featured" ? "clientText.contrastText" : "clientHeading.items"}
                lh={props.titleLH ? props.titleLH : props.newsVariant === "featured" ? "wide" : "medium"}
                family="secondary"
                weight="medium"
              >
                {decodeHtmlEntities(props.title)}
              </Typography>
              {props.field_body &&
                <Col className="grid-item-card-description-container">
                  <PostExcerpt
                    className="grid-item-card-description"
                    excerptColor={props.newsVariant === "featured" ? "clientText.contrastText" : "clientText.main"}
                    owner="client"
                    variant="body2"
                    lh="medium"
                    body={props.field_body}
                    weight="light"
                  />
                </Col>
              }
              {!props.cardActions &&
                (
                  props.event ?
                    <Col className="footer-container mt-auto">
                      {renderFooter()}
                    </Col>
                    :
                    props.companyDirectory ?
                      <Row className={`footer-container ${props.disableFooterMargin ? '' : 'mt-auto'}`}>
                        <Col className="footer-container-redactor" xs={12}>
                          {renderFooter()}
                        </Col>
                      </Row>
                      :
                      <Row className="footer-container mt-auto">
                        <Col xs={12}>
                          {renderFooter()}
                        </Col>
                      </Row>
                )
              }
              {(props.cardActions && props.newsVariant === 'sticky') &&
                renderCardActions()
              }
            </Col>
          </Row>
        </Box>
      </Box>
    )
  }

  const renderFooter = () => {
    return (
      <footer className={`${itemClass}__footer grid-item__footer item__footer container px-0`}>
        <Row className="d-flex justify-content-between mx-0 gx-0">
          <Col xs={props.chipWidth ?? 8} md={props.chipWidth ? props.chipWidth - 2 : 6} xxl={props.chipWidth ?? 8} className={"d-flex align-items-center category-chip-col"}>
            {props.author && renderAuthor()}
            {renderChip(props?.category_label ? props?.category_label : "Uncategorized")}
          </Col>
          {!props.event ?
            <>
              <Col xs={"auto"} className="footer-right">
                <Row className={`align-items-center gx-0 ${!favoritesEnabled ? "pe-1" : ""}`}>
                  <Col xs={props.chipWidth ?? "auto"} className={`timestamp pe-0 ${!favoritesEnabled ? "me-1" : ""}`}>
                    {props.footerDate &&
                      <Col className="d-flex align-items-center">
                        <Typography
                          variant={props.authorVariant || 'body2'}
                          owner="client"
                          color="clientText.main"
                          family="primary"
                          weight="regular"
                        >
                          {formatTime(props.created, true, true, true)}
                        </Typography>
                      </Col>
                    }
                    {props.newsVariant === "featured" && clientTheme.components.card.date.featuredChip ?
                      renderChip(props.created ? timestamp : "", "date")
                      :
                      props.companyDirectory ?
                        (<Typography
                          variant="caption"
                          className={`timestamp__text`}
                          owner="client"
                          color="clientPrimary.main">
                          {props.created ? `Updated ${timestamp}` : ""}
                        </Typography>
                        ) :
                        (<Typography
                          variant="caption"
                          className={`timestamp__text`}
                          owner="client"
                          color="clientDate.main">
                          {props.changed ? timestamp : ""}
                        </Typography>)
                    }
                  </Col>
                  {favoritesEnabled && !props.companyDirectory && !props.disableFavoriteBtn &&
                    <FavouriteBtn
                      className="favorite-col ps-0"
                      id={props.nid || props.id}
                      contentType={props.contentType}
                      mode={props.newsVariant}
                    />
                  }
                </Row>
              </Col>
            </>
            :
            favoritesEnabled ?
              <FavouriteBtn
                id={props.nid || props.id}
                contentType={props.contentType}
                mode={props.newsVariant}
              />
              :
              <Col lg={"auto"} className="d-none d-lg-flex align-items-center">
                <Button
                  variant="screenItemBtn"
                  mode="default"
                >
                  <OpenNewIcon
                    sx={{
                      fontSize: "0.9rem"
                    }}
                  />
                </Button>
              </Col>
          }
        </Row>
      </footer>
    )
  }

  const renderCardActions = () => {
    return (
      <Col xs="auto" className="footer-container mt-auto pt-2">
        <footer className={`${itemClass}__footer grid-item__footer item__footer card-actions mt-auto w-100`}>
          <Row className="d-flex justify-content-between gx-0">
            <Col xs={props.chipWidth ?? "auto"} className="d-flex align-items-center category-chip-col">
              {!props.disableCategory &&
                renderChip(props?.category_label ? props?.category_label : "Uncategorized")
              }
            </Col>
            <Col xs={"auto"} className="timestamp pe-0">
              <Row className="w-100 d-flex gx-0">
                {props.children}
              </Row>
            </Col>
          </Row>
        </footer>
      </Col>
    )
  }

  const renderChip = (content, type) => {
    if (props.disableChip) {
      return (<></>);
    }
    return (
      <Chip
        className="category-chip"
        variant={props.newsVariant === "featured" ? "featured" : "default"}
        owner="client"
        role="metaData"
        size="small"
        label={
          <Typography
            variant={`${clientTheme.components.chip.text.variant ?? "caption"}`}
            weight={`${clientTheme.components.chip.text.weight ?? ""}`}
            lh={`${clientTheme.components.chip.text.lh ?? ""}`}
            owner="client"
          >
            {clientTheme.components.chip.icon && type !== "date" &&
              < TagIcon className="me-1" sx={{ fontSize: "0.9rem" }} />
            }
            {content}
          </Typography>
        } />
    )
  }

  const renderAuthor = () => {
    return (
      <Row>
        <Col xs={"auto"} className="d-flex align-items-center pe-0">
          <UserImage
            className="ratio ratio-1x1"
            owner="client"
            sub_variant="user-image"
            name={props.author.name}
            image={props.author.image}
          />
        </Col>
        <Col xs={"auto"} className="d-flex align-items-center ps-2">
          <Typography
            className="author-name"
            variant={props.authorVariant || 'body2'}
            owner="client"
            color="clientText.main"
            family="primary"
            weight="regular"
          >
            {props.author.name}
          </Typography>
        </Col>
      </Row>
    );
  }

  return (
    <Col
      key={`bundle-${props.bundle || 'undefined'}-id-${props.nid}`}
      className={`grid-item-column ${props.itemClass}-item-column ${props.newsVariant ? props.newsVariant : ""} ${props.cardActions ? "card-actions" : ""}`}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
      xxl={props.xxl}
    >
      <Box className={`${props.itemClass}-card grid-item-card item ${props.newsVariant ? props.newsVariant : ""} ${isHovered ? "hovered" : ""}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {props.linkTo &&
          <Link
            to={`/${props.route}/` + props.nid}
          >
            {renderActionArea()}
          </Link>
        }
        {props.cardAction &&
          renderActionArea()
        }
        {(props.cardActions) && (
          props.newsVariant ?
            props.newsVariant === 'sticky' || props.newsVariant === 'featured' ?
              '' :
              renderCardActions() :
            renderCardActions())
        }
      </Box>
    </Col>
  )
}