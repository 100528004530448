// Dependencies
import React from 'react';

// Modules
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Gallery from 'react-photo-gallery';

// App
import { serverUrl, filesystem_root, subdomain, apiEndpoints } from '../../config';
import { getOnlineNode } from '../../core/getNode';
import { postNode } from '../../core/postNode';
import { deleteRequest } from '../../core/delete';
import PostTitle from '../../partials/postTitle';
import PostBody from '../../partials/postBody';
import { alertMessages } from '../../partials/alertMessages';
import Error from '../../partials/error';
import SkeletonSingleScreen from '../../partials/skeleton-screens/skeletonSingleScreen';
import CommentSection from '../../partials/comments/commentSection';
import { formatTime } from '../../utils/helpers';

// UI components
import { Carousel, Row, Col } from 'react-bootstrap';
import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Chip, Typography, Button, Dialog, Box } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FullscreenOutlined, FullscreenExitOutlined } from '@mui/icons-material';
import SinglesHeader from '../../partials/singlesHeader';

import { getImageDimensions } from './imageProcessor';

export default function GallerySingle(props) {
  // dummyData
  const dummyData = {
    base_url: 'https://dev-cms.companyapp.net',
    body: `<p>Explore the harmony of shapes and lines in modern cityscapes. This gallery captures the intricate patterns and bold designs of contemporary architecture, showcasing the artistry behind urban structures.</p>`,
    created: '1692092245',
    field_allow_comments: 'False',
    field_allow_reactions: 'False',
    field_category: '1189',
    field_feature: 'True',
    field_featured_image: '/sites/default/files/styles/featured_image/public/2023-08/businessman-holds-mobile-phone-displays-graphic-graph-showing-statistics-copy.jpg?h=7ec1598e&itok=E5MZyJ0M',
    nid: '6',
    sticky: 'True',
    summary: 'Explore the harmony of shapes and lines in modern cityscapes. This gallery captures the intricate patterns and bold designs of contemporary architecture, showcasing the artistry behind urban structures.',
    title: 'Urban Geometry',
    uid: '262',
  }

  const [isLoading, setIsLoading] = useState(true);
  const [isPaginating, setIsPaginating] = useState(false);
  const [post, setPost] = useState(dummyData);
  const [isCommentsLoading, setIsCommentsLoading] = useState(true);
  const [gallery, setGallery] = useState([]);
  const [comments, setComments] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [total_pages, setTotal_Pages] = useState(0);
  const [isReactionsLoading, setIsReactionsLoading] = useState(true);
  const [categoryName, setCategoryName] = useState(null);
  const [reactions, setReactions] = useState(
    {
      hasReacted: false,
      likes: 0,
      love: 0,
      suprised: 0,
    },);

  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [showViewer, setShowViewer] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [viewFullscreen, setViewFullscreen] = useState(false);

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams();
  const fullscreenRef = useRef(null);

  useEffect(() => {
    loadNode()
  }, []);
  useEffect(() => {
    if (post) {
      getCategory()
    }
  }, [post]);

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */

  const setData = (_data) => {
    setPost(_data);
    setIsError(false);
    getImageDimensions(_data.field_gallery)
      .then((newGallery) => {
        setGallery(newGallery);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(true, 0, 'Error processing gallery');
        setIsLoading(false);
      });
    // getComments();
    // getReactions();
    // getCategory(_data.field_category);
  }

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  const loadNode = () => {
    const nid = params.id;

    if (nid) {
      getContent(nid);
      // getComments(nid)
    } else {
      setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (nid) => {
    let path = `${apiEndpoints.gallery}?nid=${nid}&status=1&promote=1&_format=json`

    getOnlineNode(path, user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          setData(response.data.rows[0]);
        } else {
          setIsLoading(false);
          setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        if (_error.response) {
          setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        }
        else if (_error.request) {
          setError(true, 0, alertMessages.requestError.message);
        } else {
          setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  const getCategory = (id) => {
    if (post.field_category) {
      getOnlineNode(
        // `api/categories?vid=news`,
        `api/categories?tid=${post.field_category}`,
        user.access_token,
      )
        .then((response) => {
          let categoryTemp = response.data.length > 0 ? response.data[0] : { title: '' }
          setCategoryName(categoryTemp.title)
        })
        .catch((_error) => {
          if (_error.response) {
            setError(
              true,
              _error.response.status,
              _error.response.statusText,
            );
          } else if (_error.request) {
            //console.log('@rest request: ', _error.request);
            setError(true, 0, alertMessages.requestError.message);
          } else {
            //console.log('@rest unknown: ', _error);
            setError(true, 0, alertMessages.unkownError.message);
          }
        });
    }
  }

  const galleryElement = ({ photo, index }) => {
    return (
      <Button
        className='gallery-element'
        style={{
          width: photo.width,
          height: photo.height
        }}
        onClick={() => {
          setActiveIndex(index);
          setShowViewer(true);
        }}
      >
        <img src={photo.src} alt='' />
      </Button>
    )
  }

  const renderGallery = () => {
    return (
      <Gallery photos={gallery}
        targetRowHeight={180}
        renderImage={galleryElement}
      />
    )
  }

  const renderPostDescription = (post) => {
    return (
      <Typography
        className='post-video-description'
        owner='client'
        variant='body1'
        color='clientText.main'
        weight='light'
      >
      </Typography>
    )
  }

  const enterFullscreen = () => {
    const element = fullscreenRef.current;

    if (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { // For Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { // For Chrome, Safari and Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { // For IE/Edge
        element.msRequestFullscreen();
      }
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return (
        <Error
          status={errorStatus}
          message={errorMessage}
        />
      );
    } else {
      let category = 'Uncategorised';

      if (categoryName) {
        category = categoryName;
      }

      if (post.categoryLabel) {
        category = post.categoryLabel;
      }

      return (
        <main className='galleries single'>
          <article className='article'>
            <SinglesHeader
              route={"/Galleries"}
              backBtnText={"Galleries"}
              category={post.category_labels || 'Uncategorised'}
              timestamp={post.created}
              title={post.title}
            />
            <Row className='gallery-container'>
              {renderGallery()}
            </Row>
            <PostBody body={post.body} />
            <div className='article__body'>
            </div>
            {user && user.current_user && (
              <CommentSection
                nid={post.nid}
                field_allow_reactions={post.field_allow_reactions}
                field_allow_comments={post.field_allow_comments}
              />
            )}
          </article>
          <Dialog className='gallery-image-viewer'
            maxWidth='lg'
            fullWidth
            open={showViewer}
            onClose={() => {
              setShowViewer(false);
              setViewFullscreen(false);
            }}
            fullScreen
            ref={fullscreenRef}
          // fullScreen={fullScreen}
          // sx={{
          //   "& .MuiPaper-root": !fullScreen ? {
          //     width: '80%',
          //     height: '80%'
          //   } : {}
          // }}
          >
            <Button className="close-viewer-btn"
              onClick={() => {
                setShowViewer(false)
                setViewFullscreen(false);
              }}
            >
              <CloseRoundedIcon
              />
            </Button>
            <Button className="fullscreen-viewer-btn"
              onClick={() => {
                if (viewFullscreen) {
                  exitFullscreen();
                  setViewFullscreen(false)
                }
                else {
                  enterFullscreen();
                  setViewFullscreen(true)
                }
              }
              }
            >
              {viewFullscreen ?
                <FullscreenExitOutlined />
                :
                <FullscreenOutlined />
              }
            </Button>
            <Carousel
              className='image-viewer-carousel'
              activeIndex={activeIndex}
              controls={true}
              indicators={false}
              slide={true}
              onSelect={(index) => setActiveIndex(index)}
              interval={null}
              touch={true}
            >
              {gallery.map((e, i) => {
                return (
                  <Carousel.Item className='image-viewer-carousel-item'>
                    <Box className='image-item-container'>
                      <Box className='image-item-reducer'>
                        <img className='image-item' src={e.src} alt={e.title} data-description={e.description} />
                      </Box>
                      <Box className="image-caption-container">
                        <Typography className="image-caption"
                          owner='admin'
                          variant='body2'
                          color='adminText.contrastText'
                          weight='regular'
                          align='center'
                        >
                          {e.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </Dialog>
        </main>
      );
    }
  }
}

