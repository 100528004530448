// Dependencies
import React from "react";

// Modules
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

// App
import { apiEndpoints } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import PostBody from "../../partials/postBody";
import Error from "../../partials/error";
import SkeletonSingleScreen from "../../partials/skeleton-screens/skeletonSingleScreen";
import CommentSection from "../../partials/comments/commentSection";
import PostImage from "../../partials/postImage";
import { logCustomEvent } from "../../core/analytics";

// UI components
import SinglesHeader from "../../partials/singlesHeader";

export default function NewsSingle(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState(null);
  const [categoryName, setCategoryName] = useState(null);

  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams();
  const [searchParams] = useSearchParams();

  const hash = searchParams.get("hash");

  useEffect(() => {
    loadNode();
  }, []);

  useEffect(() => {
    if (post && post.nid) {
      // getComments();
    }
  }, [post]);

  const loadNode = () => {
    const nid = params.id;

    if (nid) {
      getContent(nid);
      // getComments(nid)
    } else {
      setErrorStatus(404);
      setErrorMessage("Not found");
      setIsError(true);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (nid) => {
    let path = `${apiEndpoints.news}/all/${nid}?_format=json`;

    if (hash) {
      path = `/api/v2/shared?bundle=news&nid=${nid}&authkey=${hash}`;
    }

    getOnlineNode(path, user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          setPost(response.data.rows[0]);
          logCustomEvent("read", response.data.rows[0], "News");
          setIsError(false);
          setIsLoading(false);
        } else {
          setErrorStatus(404);
          setErrorMessage("No data");
          setIsError(true);
        }
      })
      .catch((_error) => {
        if (_error?.response?.status === 403) {
          setErrorStatus(403);
          setErrorMessage("You do not have access to view this content.");
          setIsError(true);
          setIsLoading(false);
        } else {
          setErrorStatus(500);
          setErrorMessage("Failed to get data");
          setIsError(true);
          setIsLoading(false);
        }
      });
  };

  const renderPostImage = (post) => {
    if (post.field_featured_image) {
      return <PostImage imgSrc={post.field_featured_image} nid={post.nid} />;
    }
  };

  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return <Error status={errorStatus} message={errorMessage} />;
    } else {
      let category = "Uncategorised";

      if (categoryName) {
        category = categoryName;
      }

      if (post.category_labels) {
        category = post.category_labels;
      }

      return (
        <main className="news  single">
          <article className="article">
            <SinglesHeader
              route={"/news"}
              backBtnText={"Latest News"}
              category={category}
              timestamp={post.created}
              title={post.title}
            />
            {renderPostImage(post)}
            <div className="article__body">
              <PostBody body={post.body} />
            </div>
            {user && user.current_user && (
              <CommentSection
                nid={post.nid}
                field_allow_reactions={post.field_allow_reactions}
                field_allow_comments={post.field_allow_comments}
              />
            )}
          </article>
        </main>
      );
    }
  }
}
