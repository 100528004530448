import React from "react";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getOnlineNode } from "../../core/getNode";

import { Row, Col } from "react-bootstrap";
import { Typography, Box } from "@mui/material";
import ArrowIcon from '@mui/icons-material/ArrowForwardRounded';

export default function ForumTopic(props) {
  const [hovered, setHovered] = useState(false);
  const [userName, setUserName] = useState('');
  const [firstName, setUserFirstName] = useState('');
  const [lastName, setUserLastName] = useState('');
  const [topicData, setTopicData] = useState('');

  const user = useSelector((state) => state).authReducer.user;

  useEffect(() => {
    getTopicData();
  }, []);


  const parseHtml = (string) => {
    let parser = new DOMParser()
    return parser.parseFromString(string, 'text/html').body.innerText
  };


  const formatTime = (unixTime) => {

    const dateCreated = new Date(parseInt(unixTime) * 1000)
    const day = dateCreated.toLocaleString("en-GB", { day: "2-digit" });
    const month = dateCreated.toLocaleString("en-GB", { month: "2-digit" });
    const year = dateCreated.toLocaleString("en-GB", { year: "2-digit" });
    const hour = dateCreated.toLocaleString("en-GB", { hour: "2-digit" });
    const minute = dateCreated.toLocaleString("en-GB", { minute: "2-digit" });
    const timestamp = `${day}.${month}.${year} (${hour}:${minute})`;

    return timestamp;
  }

  const getTopicData = () => {
    const path = `api/forum/comments/${props?.item?.nid}?status=1&promoted=1&items_per_page=1&sort_by=created&sort_order=DESC`;
    getOnlineNode(path)
    .then((response) => {
      setUserName(response.data.rows[0].name);
      setUserFirstName(response.data.rows[0].field_first_name);
      setUserLastName(response.data.rows[0].field_last_name);
      setTopicData(response);
    }).catch((error) => {
      // error
    });
  };



  return (
    <Box
      className="forum-topic-item"
      onMouseEnter={() => { setHovered(true) }}
      onMouseLeave={() => { setHovered(false) }}
    >
      <Link
        className="w-100"
        to={`/forums/${props.item?.field_forum}/${props.item?.nid}`}
      >
        <Row>
          <Col xs={1} className="flex-grow-1">
            <Row className="align-items-center">
              <Col xs="auto d-flex align-items-center">
                <ArrowIcon
                  sx={{
                    color: hovered ? "clientPrimary.main" : "clientText.main",
                    fontSize: '1rem',
                    transition: "all .5s ease"
                  }}
                />
              </Col>
              <Col
                className="px-0"
              >
                <Typography
                  className="forum-topic-item-title "
                  owner="client"
                  color={hovered ? "clientPrimary.main" : "clientText.main"}
                  variant="body2"
                  weight="light"
                  lh="x-wide"
                  sx={{
                    transition: "all .5s ease"
                  }}
                >
                  {parseHtml(props.item?.title)}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col xs={2}>
            <Typography
              owner="client"
              color="clientText.main"
              variant="body2"
              weight="light"
              lh="x-wide"
            >
              {`${props.item?.field_first_name} ${props.item?.field_last_name}`}
            </Typography>
          </Col>
          <Col xs={1}>
            <Typography
              owner="client"
              color="clientText.main"
              variant="body2"
              weight="light"
              lh="x-wide"
            >
              {props.item?.comment_count}
            </Typography>
          </Col>
          <Col xs={3}>
            <Row>
              <Typography
                owner="client"
                color="clientText.main"
                variant="body2"
                weight="light"
                lh="base"
              >
                {topicData?.rows && topicData.rows?.length > 0 ? formatTime(topicData.rows[0].created) : formatTime(props.item.created)}
              </Typography>
            </Row>
            <Row>
              <Typography
                owner="client"
                color="clientText.main"
                variant="body2"
                weight="light"
                lh="base"
              >
                {`By ${firstName && lastName ? `${firstName} ${lastName}` : userName}`}
              </Typography>
            </Row>
          </Col>
        </Row>
      </Link>
    </Box>
  )
}