import React from "react";

import { favoritesEnabled, serverUrl } from "../../config";
import { Col } from "react-bootstrap";
import { Button } from "@mui/material";
import GridItem from "../../partials/gridItem";
import FavouriteBtn from "../../partials/favouriteBtn";
import { useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/CheckBoxOutlined';

export default function TrainingItem(props) {
  const navigate = useNavigate();

  return (
    <GridItem
      xs={12} sm={6} md={3}
      itemClass="training"
      bundle={props.item?.bundle}
      nid={props.item?.nid}
      title={props.item?.title}
      field_body={props.item?.summary ? props.item?.summary : props.item?.body}
      category_label={props.item.category_labels}
      created={props.item?.created}
      hasImg={true}
      imgSrc={`${serverUrl}/${props.item.field_featured_image}`}
      alt={`Poster for test ${props.item?.nid}`}
      cardAction={true}
      cardActionCallBack={() => navigate(`/training/${props.item?.nid}`)}
      cardActions={true}
      chipWidth={8}
    >
      {props.item.submitted &&
        <Col xs={"auto"} className='d-flex align-items-center'>
          <Button
            variant="screenItemBtn"
            mode="default"
            onClick={() => { }}
          >
            <CheckIcon sx={{ fontSize: "0.9rem" }} />
          </Button>
        </Col>
      }
      {favoritesEnabled && (
        <FavouriteBtn
          id={props.item.nid}
          mode={"default"}
          contentType={props.contentType}
        />
      )}
    </GridItem>
  )
}