import React from "react";

import { serverUrl } from "../../config";
import GridItem from "../../partials/gridItem";
import { useNavigate } from "react-router-dom";

export default function PodcastItem(props) {
  const navigate = useNavigate();

  return (
    <GridItem
      xs={12}
      sm={6}
      md={3}
      itemClass="podcast"
      bundle={props.item?.bundle}
      nid={props.item?.nid}
      title={props.item?.title}
      category_label={props.item.category_labels}
      field_body={props.item?.summary ? props.item?.summary : props.item?.body}
      created={props.item?.created}
      hasImg={true}
      imgSrc={`${serverUrl}/${props.item.field_featured_image}`}
      cardAction={true}
      cardActionCallBack={() => navigate(`/podcasts/${props.item?.nid}`)}
      contentType={props.contentType}
    >
    </GridItem>
  );
}
