import React from "react";

import { useState } from "react";
import { Snackbar, Alert, Button, Tooltip } from "@mui/material";
import { Col } from "react-bootstrap";
import CopyIcon from "@mui/icons-material/ContentCopyOutlined";
import OpenNewIcon from "@mui/icons-material/OpenInNewOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import GridItem from "../../partials/gridItem";
import ListItem from "../../partials/listItem";
import FavouriteBtn from "../../partials/favouriteBtn";
import { useWindowSize } from "../../partials/windowSize";
import { client } from "../../config";
import { logCustomEvent } from "../../core/analytics";

export default function LinkItem(props) {
  const [snackOpen, setSnackOpen] = useState(false);
  const { width } = useWindowSize();

  const copyToClipboard = (string) => {
    navigator.clipboard.writeText(string);
    setSnackOpen(true);
  };

  const iconSize = "0.9rem";

  let listItem = {
    id: props.item?.nid,
    title: props.item?.title,
    category_label: props.item?.category_labels,
    className: "link",
  };

  const parseBody = (string) => {
    let parser = new DOMParser();
    return parser.parseFromString(string, "text/html").body.innerText;
  };

  return (
    <>
      {props.gridView && (
        <GridItem
          xs={12}
          md={3}
          itemClass="link"
          bundle={props.item?.bundle}
          title={props.item?.title}
          cardAction={true}
          cardActionCallBack={() => {
            if (props.item?.url) {
              window.open(props.item.url);
              logCustomEvent("read", props.item, "Link");
            }
          }}
          cardActions={true}
          category_label={props.item?.category_labels}
        >
          {client !== "equans" && (
            <Col xs={"auto"} className="d-flex align-items-center">
              <Tooltip
                title={
                  props.item?.summary
                    ? parseBody(props.item?.summary)
                    : parseBody(props.item?.body)
                }
                placement="bottom"
              >
                <Button
                  variant="screenItemBtn"
                  mode="default"
                  sx={
                    props.item?.summary && props.item?.body
                      ? {}
                      : {
                        pointerEvents: "none",
                        background: "transparent",
                        color: "rgba(0, 0, 0, 0.26)",
                      }
                  }
                >
                  <InfoIcon sx={{ fontSize: iconSize }} />
                </Button>
              </Tooltip>
            </Col>
          )}
          <Col xs={"auto"} className="d-flex align-items-center">
            <Button
              variant="screenItemBtn"
              mode="default"
              onClick={() => {
                if (props.item?.url) {
                  copyToClipboard(props.item.url);
                }
              }}
            >
              <CopyIcon sx={{ fontSize: iconSize }} />
            </Button>
          </Col>
          <Col xs={"auto"} className="d-flex align-items-center">
            <Button
              variant="screenItemBtn"
              mode="default"
              onClick={() => {
                if (props.item?.url) {
                  window.open(props.item.url, "_blank");
                }
                logCustomEvent("read", props.item, "Link");
              }}
            >
              <OpenNewIcon sx={{ fontSize: iconSize }} />
            </Button>
          </Col>
          <FavouriteBtn
            id={props.item.nid}
            contentType={props.contentType}
            mode={"default"}
          />
        </GridItem>
      )}

      {!props.gridView && (
        <ListItem
          active={props.activeDetail === props.item?.nid}
          owner="client"
          item={listItem}
          idEnabled={width < 576 ? false : true}
          categoryEnabled={width < 768 ? false : true}
          infoBtn={client === "equans" ? false : true}
          handleInfo={() => props.setActiveDetails(props.item?.nid)}
          infoDisabled={!props.item?.summary && !props.item?.body}
          accordInfo
          accordInfoBody={
            props.item?.summary
              ? parseBody(props.item?.summary)
              : parseBody(props.item?.body)
          }
          copyBtn
          handleCopy={() => {
            if (props.item?.url) {
              copyToClipboard(props.item.url);
            }
          }}
          openBtn
          openBtnDisabled={false}
          handleOpen={() => {
            if (props.item?.url) {
              window.open(props.item.url, "_blank");
              logCustomEvent("read", props.item, "Link");
            }
          }}
          contentType={props.contentType}
        ></ListItem>
      )}
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Link copied!
        </Alert>
      </Snackbar>
    </>
  );
}
