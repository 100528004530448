import React from "react";

// Modules
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// App
import { apiEndpoints, serverUrl, favoritesEnabled, counterEnabled } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import { paginator } from "../../core/paginator";
// import Pagination from '../../core/pagination';
import Pagination from "../../partials/pagination";
import PageHeader from "../../partials/pageHeader";
import CategorySelector from "../../partials/categorySelector";
import { alertMessages } from "../../partials/alertMessages";
import Error from "../../partials/error";
// import { _checkContent } from '../../core/checkContent';
import { getFavoritesByContentType, favoritesContentType } from "../../utils/helpers";
import NoData from "../../partials/noData";
import ClientTheme from "../../core/clientTheme";
import EventItem from "./eventItem";
import ScreenCategories from "../../partials/screenCategories";
import { patchNode } from '../../core/postNode';

// UI components
import { Row, Col } from "react-bootstrap";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Typography,
} from "@mui/material";
import CompanyappLoader from "../../partials/companyappLoader";
import EventsIcon from "@mui/icons-material/CalendarTodayOutlined";

const contentType = favoritesContentType("event");

export default function Events(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [index, setIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [token, setToken] = useState("");
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(0);
  const [categoryOpen, setCategoryOpen] = useState(false);

  const [totalPages, setTotalPages] = useState(0);

  const user = useSelector((state) => state).authReducer.user;
  const counter = useSelector((state) => state).counterReducer;
  const clientTheme = { ...ClientTheme() };
  const favorites = useSelector((state) => state).favoritesReducer;
  
  const favStrings = getFavoritesByContentType(favorites, contentType, true);

  // hooks
  const dispatch = useDispatch();

  useEffect(() => {
    if (counterEnabled) {
      updateRemoteCounter();
    }
  }, []);

  useEffect(() => {
    if (categories.length <= 1) {
      getCategories();
    }
    if ((activeIndex === 1 && favStrings !== "") || activeIndex === 0) {
      getContent(1);
    } else {
      setData([]);
      setIsLoading(false);
    }
  }, [selectedCategoryItem, activeIndex]);

  useEffect(() => {
    if (activeIndex === 1) {
      if (favStrings !== "") {
        getContent(1);
      } else {
        setData([]);
        setIsLoading(false);
      }
    }
  }, [favStrings]);

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const _setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsRefreshing(false);

    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  const organizeData = (data) => {
    const groupedData = {};

    data.forEach((e) => {
      let unixTime;

      if (e.field_start_date) {
        unixTime = new Date(parseInt(e.field_start_date.trim()) * 1000);
      } else if (e.field_end_date) {
        unixTime = new Date(parseInt(e.field_end_date.trim()) * 1000);
      } else {
        return;
      }

      const yearMonthKey = `${unixTime.getFullYear()}-${String(
        unixTime.getMonth() + 1
      ).padStart(2, "0")}`;
      const month = unixTime.toLocaleString("en-GB", { month: "long" });
      const year = unixTime.getFullYear();

      if (!groupedData[yearMonthKey]) {
        groupedData[yearMonthKey] = {
          month: `${month} ${year}`,
          events: [],
        };
      }

      groupedData[yearMonthKey].events.push(e);
    });

    const sortedKeys = Object.keys(groupedData);
    const sortedData = sortedKeys.reduce((acc, key) => {
      acc[key] = groupedData[key];
      return acc;
    }, {});

    setData(sortedData);
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (_currentPage) => {
    let path = `${
      apiEndpoints.events
    }?status=1&promote=1&items_per_page=${pageSize}&page=${_currentPage - 1}`;

    const tid = selectedCategory;
    if (activeIndex === 1) {
      path = `${
        apiEndpoints.events
      }/all/${favStrings}?status=1&promote=1&items_per_page=${pageSize}&page=${
        _currentPage - 1
      }`;

      if (tid !== 0) {
        path = `${
          apiEndpoints.events
        }/${tid}/${favStrings}?status=1&promote=1&items_per_page=${pageSize}&page=${
          _currentPage - 1
        }`;
      }
    } else {
      if (tid !== 0) {
        path = `${
          apiEndpoints.events
        }/${tid}/all?status=1&promote=1&items_per_page=${pageSize}&page=${
          _currentPage - 1
        }`;
      }
    }

    getOnlineNode(path, user.access_token)
      .then((response) => {
        // dataHandler(response.data);

        organizeData(response.data.rows);
        setTotalItems(response.data.pager.total_items);
        setTotalPages(response.data.pager.total_pages);
        setIsLoading(false);
      })
      .catch((_error) => {
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          _setError(true, _error.response.status, _error.response.statusText);
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getCategories
   * @description Retrives the category data from an API / Fallback to local realm object if there is no connection
   */
  const getCategories = () => {
    getOnlineNode(
      `${apiEndpoints.categories}event_categories`,
      user.access_token
    )
      .then((response) => {
        renderCategories(response.data);
      })
      .catch((_error) => {
        if (_error.response) {
          _setError(true, _error.response.status, _error.response.statusText);
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  const onPaginate = (_index) => {
    setIsLoading(true);

    setCurrentPage(_index);
    getContent(_index);
  };

  /**
   * @function renderCategories
   * @description Updates the state with the retrived categories
   * @param {object} _categories
   */
  const renderCategories = (_data) => {
    let categories = [
      {
        value: 0,
        label: "All Events",
      },
    ];

    _data.forEach((item) => {
      categories.push({
        value: item.tid[0].value,
        label: item.name[0].value,
      });
    });

    setCategories(categories);
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  const handleCategorySelection = (value) => {
    setIsLoading(true);

    setIsError(false);
    setCurrentPage(1);
    setSelectedCategory(parseInt(value, 10));
    setSelectedCategoryItem(value);
    setCategoryOpen(false);
  };

  // Pagination component
  const renderPagination = () => {
    if (totalItems <= pageSize) {
      return null;
    } else {
      return (
        <Pagination
          owner="client"
          containerClasses={`creator-pagination d-flex justify-content-center align-items-center mt-4`}
          previousDisabled={currentPage === 1 ? true : false}
          nextDisabled={currentPage === totalPages ? true : false}
          currentPage={currentPage}
          onPrevious={() => {
            onPaginate(currentPage - 1);
          }}
          onNext={() => {
            onPaginate(currentPage + 1);
          }}
        />
      );
    }
  };

  const getCategoryName = (id) => {
    let categoryName = categories.find((e) => e.value === id);
    return categoryName ? categoryName.label : "";
  };

  const dataComponent = () => {
    if (typeof data !== "undefined") {
      let dataKeys = Object.keys(data);
      return (
        <>
          {dataKeys.map((key, index) => {
            return (
              <React.Fragment key={`row-${index}`}>
                <Row className="pb-1 mt-5 gx-0 event-month-container w-100">
                  <Col xs="auto" className="d-flex align-items-center">
                    <Typography
                      owner="client"
                      variant="h5"
                      color="clientHeading.eventMonth"
                      className="d-flex align-items-center"
                      sx={{ textTransform: "uppercase" }}
                    >
                      <EventsIcon
                        className="pe-2"
                        sx={{
                          fontSize: "1.6rem",
                        }}
                      />
                      {data[key].month}
                    </Typography>
                  </Col>
                </Row>
                <Row className="gx-0">
                  {data[key].events.map((item, index) => {
                    return (
                      <React.Fragment key={`event-${index}`}>
                        <EventItem item={item} contentType={contentType} />
                      </React.Fragment>
                    );
                  })}
                </Row>
              </React.Fragment>
            );
          })}
        </>
      );
    } else {
      return <NoData activeIndex={activeIndex} />;
    }
  };

  // Updates content counter
  const updateRemoteCounter = () => {
    const updateData = {
      contentType: 'event',
      acknowledgedCount: counter?.contentCounts?.event || 0,
    };

    patchNode(
      `${serverUrl}/api/v2/counter`,
      updateData,
      user.csrf_token
    )
      .then((response) => {
        if (response.data) {
          dispatch({ type: "UPDATE_COUNTER", payload: response.data });
        }
      })
      .catch((error) => {
        // console.log('error: ', error);
      });
  };

  return (
    <>
      {isError ? (
        <Error status={errorStatus} message={errorMessage} />
      ) : (
        <main className="events  screen">
          <PageHeader
            pageName="Upcoming Events"
            filters={true}
            owner="client"
            pageNameColor="clientHeadingScreens"
            svgLogo={clientTheme.pageLogos.svg}
            pageLogo={clientTheme.pageLogos.events}
            enableFavorites={favoritesEnabled}
            categorySelector={
              <ScreenCategories
                title="Events"
                categoryName="events"
                selectedCategoryItem={selectedCategoryItem}
                categoryOpen={categoryOpen}
                setCategoryOpen={(value) => setCategoryOpen(value)}
                handleCategorySelection={(value) =>
                  handleCategorySelection(value)
                }
                categories={categories}
              />
            }
            getContent={() => {
              setActiveIndex(0);
              setIsLoading(true);
              getContent(1);
            }}
            getFavourites={() => {
              setActiveIndex(activeIndex === 0 ? 1 : 0);
              setIsLoading(true);
            }}
            activeTabIndex={activeIndex}
          />
          {isLoading ? (
            <CompanyappLoader />
          ) : (
            <>
              {Object.keys(data).length > 0 ? (
                <>{dataComponent()}</>
              ) : (
                <NoData activeIndex={activeIndex} />
              )}
              {renderPagination()}
            </>
          )}
        </main>
      )}
    </>
  );
}
