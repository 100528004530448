import React, { useState, useEffect } from "react";

import { getOnlineNode } from "../../core/getNode";
import { useSelector } from "react-redux";
import { alertMessages } from '../../partials/alertMessages';
import ForumComment from "./forumComment";
import ForumCommentReply from "./forumCommentReply";
import { serverUrl } from "../../config";
import { postNode } from "../../core/postNode";
import UserImage from "../../partials/userImage";
import CompanyappLoader from "../../partials/companyappLoader";

import { Row, Col } from "react-bootstrap";
import { TextField, Button, Typography, Badge, Box, CircularProgress } from "@mui/material";
import SendIcon from '@mui/icons-material/SendOutlined';
import DiscussionIcon from "@mui/icons-material/ChatBubbleOutlineRounded";

const pageSizeIncrement = 12;
const initialPageSize = 24;

export default function ForumCommentSection(props) {
  const [comments, setComments] = useState({});
  const [commentsArr, setCommentsArr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [moreLoading, setMoreLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [totalComments, setTotalComments] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [reply, setReply] = useState(null);
  const [comment, setComment] = useState('');
  const [userName, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userImage, setUserImage] = useState('');

  const user = useSelector((state) => state).authReducer.user;

  useEffect(() => {
    getUserData()
  }, []);

  useEffect(() => {
    getComments(currentPage);
  }, [currentPage]);

  useEffect(() => {
    processCommentsArray();
  },[comments]);

  const getUserData = (_data) => {
    let path = 'custom_user?_format=json&name=' + user.current_user.name;

    getOnlineNode(path, user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          setUserName(response.data.rows[0].name);
          setFirstName(response.data.rows[0].field_first_name);
          setLastName(response.data.rows[0].field_last_name);
          setUserImage(response.data.rows[0].user_picture);
        }
      })
      .catch((_error) => {
        // error
      });

  };

  const _setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsRefreshing(false);

    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  const getComments = (_currentPage) => {
    let path =
      `api/forum/comments/${props?.nid}?status=1&promoted=1` +
      '&items_per_page=' +
      pageSize +
      '&page=' +
      (_currentPage - 1) + '&sort_by=created&sort_order=ASC';

    getOnlineNode(path, user.access_token)
      .then((response) => {
        // dataHandler(response.data);
        setTotalComments(parseInt(response.data.pager.total_items));
        setComments((prevState) => ({ ...prevState, [currentPage]: response.data.rows }));
        setMoreLoading(false);
        setIsLoading(false);
      })
      .catch((_error) => {
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          _setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  }

  const postComment = () => {

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/comment/comment_forum',
        },
      },
      entity_id: [
        {
          target_id: props?.nid,
        },
      ],
      entity_type: [
        {
          value: 'node',
        },
      ],
      comment_type: [
        {
          target_id: 'comment_forum',
        },
      ],
      pid: [
        {
          target_id: reply?.cid ? reply.cid : null,
        }
      ],
      field_name: [
        {
          value: 'field_forum_comments',
        },
      ],
      subject: [
        {
          value: props?.subject,
        },
      ],
      comment_body: [
        {
          value: comment,
        },
      ],
    };

    postNode(
      'comment',
      data,
      user.csrf_token,
      user.access_token
    ).then((response) => {
      setComment('');
      getComments(1);
    }).catch((_error) => {
      if (_error.response) {
        //console.log('@rest response: ', _error.response);
        _setError(
          true,
          _error.response.status,
          _error.response.statusText,
        );
      } else if (_error.request) {
        //console.log('@rest request: ', _error.request);
        _setError(true, 0, alertMessages.requestError.message);
      } else {
        //console.log('@rest unknown: ', _error);
        _setError(true, 0, alertMessages.unkownError.message);
      }
    });
  }

  const renderNewComment = () => {
    return (
      <Row id="add-comment" className="add-comment-container">
        <Col xs={3} className="add-comment-author">
          <Row className="justify-content-center">
            <Col xs="auto">
              <Badge className="add-comment-author-badge">
                <UserImage
                  className={'discussion-avatar'}
                  name={firstName && lastName ? `${firstName} ${lastName}` : userName}
                  image={userImage}
                  owner="client"
                  sub_variant="user-image"
                />
              </Badge>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              xs="auto"
            >
              <Typography
                owner="client"
                variant="body2"
                weight="light"
                lh="medium"
                color="clientText.main"
              >
                Posting as:
              </Typography>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Typography
                className="add-comment-author"
                owner="client"
                variant="body2"
                weight="light"
                lh="medium"
                color="clientPrimary.main"
              >
                {firstName && lastName ? `${firstName} ${lastName}` : userName}
              </Typography>
            </Col>
          </Row>
        </Col>
        <Col xs={9} className="add-comment-content">
          <Row className="gx-0 align-items-center mb-3">
            <Col xs="auto me-2">
              <DiscussionIcon
                color="clientText.main"
                sx={{
                  fontSize: '36px'
                }}
              />
            </Col>
            <Col xs="auto">
              <Typography
                owner="client"
                color="clientText.main"
                variant="h2"
              >
                Join the discussion
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Box className="add-comment-content-input-container">
                {reply &&
                  <Row className="mb-2">
                    <Col xs={12}>
                      <ForumCommentReply
                        item={reply}
                        clearReply={() => {
                          setReply(null);
                        }}
                      />
                    </Col>
                  </Row>
                }
                <Row>
                  <Col xs={12}>
                    <TextField
                      className="w-100 add-comment-input"
                      multiline
                      placeholder="Write a comment..."
                      minRows={3}
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-end mx-0 gx-0">
                  <Col xs={"auto"}>
                    <Button
                      className="d-flex align-items-center"
                      disabled={comment.trim() === ''}
                      onClick={() => {
                        postComment();
                        setIsLoading(true);
                      }}
                    >
                      <Typography
                        className="me-1"
                        owner="client"
                        variant="subtitle1"
                      >
                        Submit
                      </Typography>
                      <SendIcon
                        sx={{
                          fontSize: "22px"
                        }}
                      />
                    </Button>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const processCommentsArray = () => {
    const keys = Object.keys(comments);
    let tempArr = [];
    keys.forEach((e) => {
      tempArr.push(...comments[e]);
    });
    setCommentsArr(tempArr);
  };

  const renderLoadMore = () => {
    return (
      <Row className="d-flex align-items-center justify-content-center">
        <Col xs={'auto'}>
          <Button
            className="lh"
            variant="contained"
            owner="admin"
            dialogbtn="true"
            dialogvariant="primary"
            onClick={() => {
              setMoreLoading(true);
              setCurrentPage((prevState) => prevState + 1);
            }}
          >
            <Typography
              className="lh"
              variant="button"
              owner="admin">
              {moreLoading ? (<CircularProgress size={20} />) : 'Load more comments'}
            </Typography>
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {isLoading ?
        <CompanyappLoader />
        :
        <div className="forum-comment-section-container container">
          {commentsArr.map((e, i) => {
            let replyItem
            if (e.pid) {
              replyItem = commentsArr.find((f) => {
                return f.cid === e.pid
              })
            }
            return (
              <ForumComment
                item={e}
                index={i}
                author={e.uid === props.authorUID}
                replyItem={replyItem}
                reply={(item) => {
                  setReply(item);
                }}
              />
            )
          })}
          {(totalComments > commentsArr.length || moreLoading) && renderLoadMore()}
          {renderNewComment()}
        </div>
      }
    </>
  )
}