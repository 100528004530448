import React from "react";
import { useNavigate } from "react-router";
import { filesystem_root, serverUrl } from "../../../config";

import GridItem from "../../../partials/gridItem";

import { navigationLabels, favoritesContentType } from "../../../utils/helpers";

import { BookOpen, Home, User, BarChart2, FileText } from "react-feather";
import {
  InsertDriveFileOutlined,
  VideocamOutlined,
  InsertLinkOutlined,
} from "@mui/icons-material";
import PieChart from "@mui/icons-material/PieChartOutlineOutlined";
import FormIcon from "@mui/icons-material/CreateOutlined";
import MicIcon from "@mui/icons-material/MicNoneRounded";
import TrainingIcon from "@mui/icons-material/SchoolOutlined";
import EventsIcon from "@mui/icons-material/CalendarTodayOutlined";
import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import ForumsIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import CallIcon from "@mui/icons-material/CallOutlined";
import CompanyDirectoryIcon from "@mui/icons-material/PeopleOutlineOutlined";
import QuizIcon from "@mui/icons-material/QuizOutlined";

export default function LatestItem(props) {
  const navigate = useNavigate();
  const item = props.item;

  const callbackRoute = (type) => {
    const route = {
      news: "news",
      blogs: "blogs",
      event: "events",
      presentation: "presentations/edit",
      video: "videos",
      podcast: "podcasts",
      course: "training",
      quiz: "quizzes",
      document: "documents",
      poll: "polls",
      webform: "forms",
      company_directory: "company_directory",
    };

    return route[type] || "";
  };

  const getLogo = (type) => {
    switch (type) {
      case "news":
        return <FileText size={20} />;
      case "blogs":
        return <ArticleIcon sx={{ fontSize: 20 }} />;
      case "event":
        return <EventsIcon sx={{ fontSize: 20 }} />;
      case "presentation":
        return <BookOpen size={20} />;
      case "video":
        return <VideocamOutlined sx={{ fontSize: 20 }} />;
      case "podcast":
        return <MicIcon sx={{ fontSize: 20 }} />;
      case "course":
        return <TrainingIcon sx={{ fontSize: 20 }} />;
      case "quiz":
        return <QuizIcon sx={{ fontSize: 20 }} />;
      case "document":
        return <InsertDriveFileOutlined sx={{ fontSize: 20 }} />;
      case "poll":
        return <PieChart sx={{ fontSize: 20 }} />;
      case "webform":
        return <FormIcon sx={{ fontSize: 20 }} />;
      case "company_directory":
        return <CompanyDirectoryIcon sx={{ fontSize: 20 }} />;
      case "gallery":
        return <CollectionsOutlinedIcon sx={{ fontSize: 20 }} />
    }
  };


  console.log('item', props.item);
  const overlineStats =
    item.hasOwnProperty("comment_count") &&
    item.hasOwnProperty("reactions");
  let imgSrc;

  if (item.field_featured_image && item.field_featured_image !== "") {
    imgSrc = `${serverUrl}${item.field_featured_image}`;
  } else if (item.thumbnail && item.thumbnail !== "") {
    imgSrc = `${filesystem_root}${item.thumbnail}`;
  } else if (item.field_image && item.field_image !== "") {
    imgSrc = `${serverUrl}${item.field_image}`;
  }

  if (item.bundle === 'video') {
    imgSrc = `${serverUrl}/api/video/thumbnail?mid=${item.mid}`
  }

  const author = {
    name: item.author_information.full_name,
    image: `${serverUrl}${item.author_information.user_picture}`,
  };

  return (
    <GridItem
      xs={12}
      sm={12}
      md={12}
      xl={6}
      itemClass="latest"
      bundle={props.item?.bundle}
      nid={item.nid}
      title={item.title || item.question}
      titleVariant={"h4"}
      titleLH={"wide"}
      field_body={item.summary || item.body}
      overlineCategory={true}
      overlineCategoryLogo={getLogo(item.bundle)}
      overlineStats={overlineStats}
      overlineCategoryVariant={"subtitle1"}
      overlineCategoryWeight={"regular"}
      commentCount={item.comment_count}
      reactionCount={item.reactions}
      category_label={navigationLabels(item.bundle)}
      author={author}
      hasImg={true}
      imgSrc={imgSrc}
      disableChip={true}
      footerDate={true}
      created={item.created}
      cardActionCallBack={() =>
        navigate(`/${callbackRoute(item.bundle)}/${item?.id}`)
      }
      cardAction={true}
      contentType={favoritesContentType(item.type)}
    />
  );
}
