const defaultState = {};

export const counterReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'UPDATE_COUNTER':
      return action.payload;

    default:
      return state;
  }
};