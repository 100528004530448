import React from "react";

import { useState } from 'react';
import { useNavigate } from "react-router";
import { Snackbar, Alert, Button, Tooltip } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import GridItem from "../../partials/gridItem";
import ListItem from "../../partials/listItem";
import { favoritesEnabled } from "../../config";
import FavouriteBtn from "../../partials/favouriteBtn";

import EmailIcon from '@mui/icons-material/EmailOutlined';
import CallIcon from '@mui/icons-material/CallOutlined';
import ChatIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

export default function ContactItem(props) {
  const [isHovered, setIsHovered] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false);
  const itemClass = "contact-item";

  const dateCreated = new Date(props.item?.created * 1000)
  const day = dateCreated.toLocaleString("en-GB", { day: "2-digit" })
  const month = dateCreated.toLocaleString("en-GB", { month: "short" })
  const year = dateCreated.toLocaleString("en-GB", { year: "2-digit" })
  const timestamp = `${day} ${month} ${year}`;

  const copyToClipboard = (string) => {
    navigator.clipboard.writeText(string);
    setSnackOpen(true)
  };

  const navigate = useNavigate();

  let name

  if (props.item.field_first_name && (props.item.field_first_name !== "")) {
    name = `${props.item.field_first_name} ${props.item.field_last_name}`;
  }
  else {
    name = props.item.name;
  }

  let listItem = {
    title: name,
    subtitle: props.item?.field_job_title,
    phone_number: props.item?.phone_number,
    mail: props.item?.mail,
    user_picture: props.item?.user_picture,
    category_label: props.item?.category_label,
    className: "contact"
  }

  const parseBody = (string) => {
    let parser = new DOMParser()
    return parser.parseFromString(string, 'text/html').body.innerText
  }




  return (
    <>
      {props.gridView &&
        <GridItem
          xs={12}
          sm={6}
          md={4}
          lg={3}
          itemClass="contact"
          bundle={props.item?.bundle}
          linkTo={true}
          route="contacts"
          title={name}
          field_body={props.item.field_job_title}
          nid={props.item.uid}
          // cardAction={true}
          // cardActionCallBack={() => { window.open(props.item?.field_website) }}
          cardActions={true}
          category_label={props.item?.category_label}
          textContainerCol={9}
          hasAvatar={true}
          user_picture={props.item?.user_picture}
        >
          <Row className="w-100 d-flex gx-0">
            <Col xs={"auto"} className="d-flex align-items-center">
              <Button
                className="contact-btn info"
                variant="screenItemBtn"
                mode="default"
                disabled={props.item?.phone_number === ""}
                onClick={() => { window.location.href = `tel:${encodeURIComponent(props.item?.phone_number)}` }}
              >
                <CallIcon sx={{ fontSize: "0.9rem" }} />
              </Button>
            </Col>
            <Col xs={"auto"} className="d-flex align-items-center">
              <Button
                className="contact-btn copy"
                variant="screenItemBtn"
                mode="default"
              // onClick={() => { copyToClipboard(props.item?.field_website) }}
              >
                <ChatIcon sx={{ fontSize: "0.9rem" }} />
              </Button>
            </Col>
            <Col xs={"auto"} className="d-flex align-items-center">
              <Button
                className="contact-btn open"
                variant="screenItemBtn"
                mode="default"
                onClick={() => { window.location.href = `mailto:${encodeURIComponent(props.item?.mail)}` }}
              >
                <EmailIcon sx={{ fontSize: "0.9rem" }} />
              </Button>
            </Col>
            {favoritesEnabled &&
              <FavouriteBtn
                id={props.item.uid}
                contentType={props.contentType}
                mode={"default"}
                isFavourite={false}
              />
            }
          </Row>
        </GridItem>
      }

      {!props.gridView &&
        <ListItem
          owner="client"
          variant="contacts"
          item={listItem}
          avatar
          categoryEnabled
          subEnabled
          phoneBtn
          handlePhone={() => { window.location.href = `tel:${encodeURIComponent(props.item?.phone_number)}` }}
          chatBtn
          emailBtn
          handleEmail={() => { window.location.href = `mailto:${encodeURIComponent(props.item?.mail)}` }}
          emailStatus={false}
          openBtn
          openBtnDisabled={false}
          handleOpen={() => { navigate(`/contacts/${props.item?.uid}`) }}
        >

        </ListItem>
      }
    </>
  )
}