import { filesystem_root } from '../../../../config'
import { CloseIcon, TextOverlayIcon } from '../../../icons/icons';
import BtnTooltip from '../../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../../tooltips/edjsTooltip';



/**
 * Build styles
 */
require('./index.css').toString();

export default class HotelTextOverlay {

  static get toolbox() {
    return {
      icon: TextOverlayIcon,
      title: 'Hotel text overlay',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to use native Enter behaviour
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  static get DEFAULT_HEADING_PLACEHOLDER() {
    return 'Overlay tagline'
  }

  static get DEFAULT_BODY_PLACEHOLDER() {
    return 'Overlay body'
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-hotel-text-overlay-container',
      contentContainer: 'cdx-hotel-text-overlay-content-container',
      wrapper: 'cdx-hotel-text-overlay',
      replaceButton: 'replace-btn',
      removeButton: 'remove-btn',
      input: 'cdx-hotel-text-overlay-input',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      mdIcon: 'md-icon',
      clear: 'clear',
      optionItem: 'logo-option-item',
      logoBtn: 'logo-btn',
      logoOptionsMain: 'logo-options-main',
      logoOptionsBackdrop: 'logo-options-backdrop',
      logoOptionsContainer: 'logo-options-container',
      logoCloseBtn: 'logo-close-btn',
      editorFont: 'editor-font',
      editorBlockModalBackdrop: 'editor-block-modal-backdrop',
      editorBlockModal: 'editor-block-modal',
      editorBlockModalTitle: 'editor-block-modal-title',
      editorBlockModalLabel: 'editor-block-modal-label',
      editorBlockModalText: 'editor-block-modal-text',
      adminFont: 'admin-font',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;
    this._data = {
      logo: data.logo || { name: '', svg: '' },
      heading: data.heading || '',
      body: data.body || '',
    };
    this._container = ''
    this._headingPlaceholder = config.headingPlaceholder ? config.headingPlaceholder : HotelTextOverlay.DEFAULT_HEADING_PLACEHOLDER;
    this._bodyPlaceholder = config.bodyPlaceholder ? config.bodyPlaceholder : HotelTextOverlay.DEFAULT_BODY_PLACEHOLDER;
    this._headingElement = this.getHeadingElement();
    this._bodyElement = this.getBodyElement();
  }
  /**
   * Check if text content is empty and set empty string to inner html.
   * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
   *
   * @param {KeyboardEvent} e - key up event
   */
  onKeyUp(e, element) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = element;

    if (textContent === '') {
      element.innerHTML = '';
    }
  }

  onKeyDown(e, element) {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      this.addLineBreak(element);
    }
  }

  addLineBreak(element) {
    const selection = window.getSelection(); // get the current selection.
    const range = selection.getRangeAt(0); // get the current range of the selection.
    const brTags = element.querySelectorAll('br');

    // we need to create 2x tags initially, 1x tag will not do anything
    // this is the default browser behavior with Enter + Shift as well
    if (brTags.length === 0) {
      const br1 = document.createElement('br');
      const br2 = document.createElement('br');
      range.insertNode(br1); // inserts the <br> element at the current cursor position.
      range.insertNode(br2); // inserts the <br> element at the current cursor position.
      range.setStartAfter(br2); // set the start of the range to the position after the <br> element.
    } else {
      const br = document.createElement('br');
      range.insertNode(br);
      range.setStartAfter(br); // set the start of the range to the position after the <br> element.
    }

    range.collapse(true); // collapse the range to the position after the <br> element.
    selection.removeAllRanges(); // remove any existing ranges from the selection.
    selection.addRange(range); // add the modified range to the selection.
  }

  getHeadingElement() {
    const tag = document.createElement('h4');
    tag.dataset.placeholder = this.api.i18n.t(this._headingPlaceholder);
    tag.innerHTML = this._data.heading || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('overlay-heading');
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    tag.addEventListener('keyup', (e) => this.onKeyUp(e, this._headingElement));
    tag.addEventListener('keydown', (e) => this.onKeyDown(e, this._headingElement));

    return tag;
  }

  getBodyElement() {
    const tag = document.createElement('p');
    tag.dataset.placeholder = this.api.i18n.t(this._bodyPlaceholder);
    tag.innerHTML = this._data.body || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('overlay-body');
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    tag.addEventListener('keyup', (e) => this.onKeyUp(e, this._bodyElement));
    tag.addEventListener('keydown', (e) => this.onKeyDown(e, this._bodyElement));

    return tag;
  }

  logoOption(logo) {
    const optionItem = document.createElement('button');

    optionItem.innerHTML = logo.svg === '' ? 'No logo' : logo.svg;
    optionItem.classList.add(this._CSS.optionItem);

    optionItem.onclick = () => {
      if (logo.svg === '') {
        this._data.logo = { name: '', svg: '' }
        this.logoBtn.innerHTML = 'No logo'
        this.logoOptionsMain.classList.remove('active');
      } else {
        this._data.logo = logo
        this.logoBtn.innerHTML = logo.svg
        this.logoOptionsMain.classList.remove('active');
      }
    };
    return optionItem;
  }

  render() {
    this._container = document.createElement('div');
    let contentContainer = document.createElement('div');

    this._container.classList.add(this._CSS.container);
    contentContainer.classList.add(this._CSS.contentContainer);


    this.logoBtn = document.createElement('button');
    BtnTooltip(this.logoBtn, EdjsTooltips.hotelTxtOverlay.logo);
    this.logoOptionsMain = document.createElement('div');
    const logoOptionsBackdrop = document.createElement('div');
    let logoOptionsContainer = document.createElement('div');
    let logoCloseBtn = document.createElement('button');


    this._container.appendChild(contentContainer);
    this.logoBtn.innerHTML = this._data.logo.svg === '' ? 'No logo' : this._data.logo.svg;
    this.logoBtn.classList.add(this._CSS.logoBtn);


    if (!this.readOnly) {
      if (this._settings?.options?.logos) {
        this.logoOptionsMain.classList.add(this._CSS.logoOptionsMain);
        logoOptionsBackdrop.classList.add(this._CSS.editorBlockModalBackdrop);
        logoOptionsContainer.classList.add(this._CSS.logoOptionsContainer, this._CSS.editorBlockModal);
        logoCloseBtn.classList.add(this._CSS.logoCloseBtn, this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear);

        logoOptionsContainer.appendChild(this.logoOption({ name: '', svg: '' }));
        this._settings?.options?.logos.map((e) => {
          let optionItem = this.logoOption(e)
          logoOptionsContainer.appendChild(optionItem);
        })

        this.logoBtn.onclick = () => this.logoOptionsMain.classList.toggle('active');


        logoCloseBtn.innerHTML = CloseIcon;
        logoCloseBtn.onclick = (e) => {
          this.logoOptionsMain.classList.toggle('active')
        };

        logoOptionsContainer.appendChild(logoCloseBtn);
        this.logoOptionsMain.appendChild(logoOptionsBackdrop);
        this.logoOptionsMain.appendChild(logoOptionsContainer);

        contentContainer.appendChild(this.logoOptionsMain);
      }
    }

    contentContainer.appendChild(this.logoBtn);
    contentContainer.appendChild(this._headingElement);
    contentContainer.appendChild(this._bodyElement);

    return this._container;
  }

  static get sanitize() {
    return {
      svg: true,
      br: true,
      b: true,
      i:true,
      sub: true,
      sup: true,
    };
  }

  save() {

    this._data.heading = this._headingElement.innerHTML;
    this._data.body = this._bodyElement.innerHTML;

    return this._data;
  }
}